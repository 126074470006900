define("ember-bootstrap/templates/components/bs-accordion/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "A+PGZ1p6",
    "block": "{\"symbols\":[\"@disabled\",\"&default\"],\"statements\":[[10,\"h5\"],[14,0,\"mb-0\"],[12],[2,\"\\n  \"],[10,\"button\"],[15,0,[31,[\"btn btn-link \",[30,[36,0],[[32,1],\"disabled\"],null]]]],[15,\"disabled\",[32,1]],[14,4,\"button\"],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ember-bootstrap/templates/components/bs-accordion/title.hbs"
  });
  _exports.default = _default;
});