define("ember-svg-jar/inlined/dashboard-vessel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.167 0h3.708v3.221H14.5v5.222l3.203 1.285L13.3 18H4.872L.288 9.731 3.5 8.443V3.22h3.667V0zm1 3.221h1.708V1H8.167v2.221zM4.176 9.25l-2.464.988L5.462 17h2.756l.184-9.23L4.176 9.25zm5.227-1.495L9.218 17H12.7l3.597-6.76-2.47-.99-4.424-1.496zm4.097.33V4.22h-9v3.855l4.414-1.543 4.586 1.55z\" fill=\"#505152\"/><path d=\"M12.5 11.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM6.5 11.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});