define("ember-bootstrap/templates/components/bs-form/element/feedback-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "L36f3DOy",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[\"form-control-feedback \",[32,0,[\"iconName\"]]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/feedback-icon.hbs"
  });
  _exports.default = _default;
});