define("ember-lifeline/utils/disposable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._setRegisteredDisposables = _setRegisteredDisposables;
  _exports.registerDisposable = registerDisposable;
  _exports.runDisposables = runDisposables;
  /**
   * A map of instances/array of disposables. Only exported for
   * testing purposes.
   *
   * @public
   */
  let registeredDisposables = new WeakMap();

  /**
   * Test use only. Allows for swapping out the WeakMap to a Map, giving
   * us the ability to detect whether disposables have all been called.
   *
   * @private
   * @param {IMap} mapForTesting A map used to ensure correctness when testing.
   */
  function _setRegisteredDisposables(mapForTesting) {
    registeredDisposables = mapForTesting;
  }

  /**
   * Registers a new disposable function to run for an instance. Will
   * handle lazily creating a new array to store the disposables per
   * instance if one does not exist. Will additionally patch an object's
   * `destroy` hook to ensure the destroyables are run/destroyed when
   * the object is destroyed.
   *
   * @function registerDisposable
   * @public
   * @param {IDestroyable} obj the instance to store the disposable for
   * @param {Function} dispose a function that disposes of instance resources
   */
  function registerDisposable(obj, dispose) {
    (true && !(typeof obj === 'object') && Ember.assert('Called `registerDisposable` where `obj` is not an object', typeof obj === 'object'));
    (true && !(typeof dispose === 'function') && Ember.assert('Called `registerDisposable` where `dispose` is not a function', typeof dispose === 'function'));
    (true && !(!obj.isDestroying) && Ember.assert('Called `registerDisposable` on a destroyed object', !obj.isDestroying));
    let disposables = getRegisteredDisposables(obj);
    disposables.push(dispose);
  }

  /**
   * Runs all disposables for a given instance.
   *
   * @function runDisposables
   * @public
   * @param {IDestroyable} obj the instance to run the disposables for
   */
  function runDisposables(obj) {
    let disposables = registeredDisposables.get(obj);
    if (disposables === undefined) {
      return;
    }
    registeredDisposables.delete(obj);
    for (let i = 0; i < disposables.length; i++) {
      disposables[i]();
    }
  }
  function getRegisteredDisposables(obj) {
    let disposables = registeredDisposables.get(obj);
    if (disposables === undefined) {
      registeredDisposables.set(obj, disposables = []);
    }
    return disposables;
  }
});