define("ember-svg-jar/inlined/right-16px", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.3 3.286A1 1 0 005.286 4.7l3.3 3.37L5.3 11.284a1 1 0 001.4 1.43l4.714-4.616-4.7-4.799A1 1 0 005.3 3.286z\" fill=\"#1E1E1E\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});