define("ember-svg-jar/inlined/compass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"10\"/><path d=\"M16.24 7.76l-2.12 6.36-6.36 2.12 2.12-6.36 6.36-2.12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "class": "feather feather-compass"
    }
  };
  _exports.default = _default;
});