define("ember-bootstrap/components/base/bs-dropdown/button", ["exports", "@ember-decorators/component", "ember-bootstrap/components/bs-button"], function (_exports, _component, _bsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   Button component with that can act as a dropdown toggler.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownButton
   @namespace Components
   @extends Components.Button
   @public
   */
  let DropdownButton = (_dec = (0, _component.classNames)('dropdown-toggle'), _dec2 = (0, _component.attributeBindings)('aria-expanded'), _dec3 = Ember.computed('isOpen'), _dec(_class = _dec2(_class = (_class2 = class DropdownButton extends _bsButton.default {
    keyDown(e) {
      this.get('onKeyDown')(e);
    }
    get 'aria-expanded'() {
      return this.isOpen ? 'true' : 'false';
    }
    didReceiveAttrs() {
      // super.didReceiveAttrs(...arguments);
      let dropdown = this.get('dropdown');
      if (dropdown) {
        Ember.run.schedule('actions', this, function () {
          if (!this.get('isDestroyed')) {
            dropdown.set('toggle', this);
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, 'aria-expanded', [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, 'aria-expanded'), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = DropdownButton;
});