define("@bagaar/ember-breadcrumbs/components/breadcrumbs-item", ["exports", "@bagaar/ember-breadcrumbs/templates/components/breadcrumbs-item"], function (_exports, _breadcrumbsItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Services
     */

    breadcrumbsService: Ember.inject.service('breadcrumbs'),
    /**
     * State
     */

    layout: _breadcrumbsItem.default,
    tagName: ''
  });
  _exports.default = _default;
});