define("ember-bootstrap/components/base/bs-carousel", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/components/bs-carousel/slide", "ember-bootstrap/mixins/component-parent", "ember-bootstrap/templates/components/bs-carousel", "ember-concurrency", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _object, _slide, _componentParent, _bsCarousel, _emberConcurrency, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
    Ember implementation of Bootstrap's Carousel. Supports all original features but API is partially different:
  
    | Description | Original | Component |
    | ------ | ------ | ------ |
    | Autoplays after first user event or on page load. | ride='carousel'\|false | autoPlay=false\|true |
    | Disable automatic cycle. | interval=false | interval=0 |
    | If first slide should follow last slide on "previous" event, the opposite will also be true for "next" event. | wrap=false\|true | wrap=false\|true |
    | Jumps into specific slide index | data-slide-to=n | index=n |
    | Keyboard events. | keyboard=false\|true | keyboard=false\|true |
    | Left-to-right or right-to-left sliding. | N/A |  ltr=false\|true |
    | Pause current cycle on mouse enter. | pause='hover'\|null | pauseOnMouseEnter=false\|true |
    | Show or hide controls  | Tag manipulation. | showControls=false\|true |
    | Show or hide indicators  | Tag manipulation. | showIndicators=false\|true |
    | Waiting time of slides in a automatic cycle. | interval=n | interval=n |
  
    Default settings are the same as the original so you don't have to worry about changing parameters.
  
    ```hbs
    <BsCarousel as |car|>
      <car.slide>
        <img alt="First slide" src="slide1.jpg">
      </car.slide>
      <car.slide>
        <img alt="Second slide" src="slide2.jpg">
      </car.slide>
      <car.slide>
        <img alt="Third slide" src="slide3.jpg">
      </car.slide>
    </BsCarousel>
    ```
  
    To better understand the whole documentation, you should be aware of the following operations:
  
    | Operation | Description |
    | ------ | ------ |
    | Transition | Swaps two slides. |
    | Interval | Waiting time after a transition. |
    | Presentation | Represents a single transition, or a single interval, or the union of both. |
    | Cycle | Presents all slides until it reaches first or last slide. |
    | Wrap | wrap slides, cycles without stopping at first or last slide. |
    ```
  
    @class Carousel
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Carousel = (_dec = (0, _component.attributeBindings)('tabindex'), _dec2 = (0, _component.classNames)('carousel', 'slide'), _dec3 = (0, _component.layout)(_bsCarousel.default), _dec4 = Ember.computed('wrap', 'currentIndex'), _dec5 = Ember.computed('childSlides.length', 'wrap', 'currentIndex'), _dec6 = Ember.computed.filter('children', function (view) {
    return view instanceof _slide.default;
  }).readOnly(), _dec7 = (0, _object.observes)('childSlides.[]', 'autoPlay'), _dec8 = Ember.computed('childSlides', 'currentIndex').readOnly(), _dec9 = Ember.computed('childSlides', 'followingIndex').readOnly(), _dec10 = Ember.computed.gt('interval', 0).readOnly(), _dec11 = (0, _object.observes)('index'), _dec12 = Ember.computed('childSlides.length'), _dec13 = Ember.computed.lte('childSlides.length', 1), _dec14 = Ember.computed.readOnly('hasInterval'), _dec15 = (0, _emberConcurrency.task)(function* () {
    yield this.get('transitioner').perform();
    yield (0, _emberConcurrency.timeout)(this.get('interval'));
    this.toAppropriateSlide();
  }).restartable(), _dec16 = (0, _emberConcurrency.task)(function* () {
    this.set('presentationState', 'willTransit');
    yield (0, _emberConcurrency.timeout)(this.get('transitionDuration'));
    this.set('presentationState', 'didTransition');
    // Must change current index after execution of 'presentationStateObserver' method
    // from child components.
    yield new Ember.RSVP.Promise(resolve => {
      Ember.run.schedule('afterRender', this, function () {
        this.set('currentIndex', this.get('followingIndex'));
        resolve();
      });
    });
  }).drop(), _dec17 = (0, _emberConcurrency.task)(function* () {
    if (this.get('shouldRunAutomatically') === false) {
      return;
    }
    yield (0, _emberConcurrency.timeout)(this.get('interval'));
    this.toAppropriateSlide();
  }).restartable(), _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class Carousel extends Ember.Component.extend(_componentParent.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tabindex", '1');
      _initializerDefineProperty(this, "slideComponent", _descriptor, this);
      _initializerDefineProperty(this, "childSlides", _descriptor2, this);
      _initializerDefineProperty(this, "currentIndex", _descriptor3, this);
      _initializerDefineProperty(this, "directionalClassName", _descriptor4, this);
      _initializerDefineProperty(this, "followingIndex", _descriptor5, this);
      _initializerDefineProperty(this, "hasInterval", _descriptor6, this);
      _initializerDefineProperty(this, "isMouseHovering", _descriptor7, this);
      _initializerDefineProperty(this, "presentationState", _descriptor8, this);
      _initializerDefineProperty(this, "shouldNotDoPresentation", _descriptor9, this);
      _initializerDefineProperty(this, "shouldRunAutomatically", _descriptor10, this);
      _initializerDefineProperty(this, "autoPlay", _descriptor11, this);
      _initializerDefineProperty(this, "wrap", _descriptor12, this);
      _initializerDefineProperty(this, "index", _descriptor13, this);
      _initializerDefineProperty(this, "interval", _descriptor14, this);
      _initializerDefineProperty(this, "keyboard", _descriptor15, this);
      _initializerDefineProperty(this, "ltr", _descriptor16, this);
      _initializerDefineProperty(this, "nextControlLabel", _descriptor17, this);
      _initializerDefineProperty(this, "pauseOnMouseEnter", _descriptor18, this);
      _initializerDefineProperty(this, "prevControlLabel", _descriptor19, this);
      _initializerDefineProperty(this, "showControls", _descriptor20, this);
      _initializerDefineProperty(this, "showIndicators", _descriptor21, this);
      _initializerDefineProperty(this, "transitionDuration", _descriptor22, this);
      _initializerDefineProperty(this, "transition", _descriptor23, this);
      _initializerDefineProperty(this, "cycle", _descriptor24, this);
      _initializerDefineProperty(this, "transitioner", _descriptor25, this);
      _initializerDefineProperty(this, "waitIntervalToInitCycle", _descriptor26, this);
    }
    /**
     * If a slide can turn to left, including corners.
     *
     * @private
     * @property canTurnToLeft
     */
    get canTurnToLeft() {
      return this.get('wrap') || this.get('currentIndex') > 0;
    }

    /**
     * If a slide can turn to right, including corners.
     *
     * @private
     * @property canTurnToRight
     */
    get canTurnToRight() {
      return this.get('wrap') || this.get('currentIndex') < this.get('childSlides.length') - 1;
    }

    /**
     * All `CarouselSlide` child components.
     *
     * @private
     * @property childSlides
     * @readonly
     * @type array
     */

    /**
     * This observer is the entry point for real time insertion and removing of slides.
     *
     * @private
     * @property childSlidesObserver
     */
    childSlidesObserver() {
      Ember.run.scheduleOnce('actions', this, this._childSlidesObserver);
    }
    _childSlidesObserver() {
      let childSlides = this.get('childSlides');
      if (childSlides.length === 0) {
        return;
      }
      // Sets new current index
      let currentIndex = this.get('currentIndex');
      if (currentIndex >= childSlides.length) {
        currentIndex = childSlides.length - 1;
        this.set('currentIndex', currentIndex);
      }
      // Automatic sliding
      if (this.get('autoPlay')) {
        this.get('waitIntervalToInitCycle').perform();
      }
      // Initial slide state
      this.set('presentationState', null);
    }

    /**
     * Indicates the current index of the current slide.
     *
     * @property currentIndex
     * @private
     */

    /**
     * The current slide object that is going to be used by the nested slides components.
     *
     * @property currentSlide
     * @private
     *
     */
    get currentSlide() {
      return this.get('childSlides').objectAt(this.get('currentIndex'));
    }

    /**
     * Bootstrap style to indicate that a given slide should be moving to left/right.
     *
     * @property directionalClassName
     * @private
     * @type string
     */

    /**
     * The following slide object that is going to be used by the nested slides components.
     *
     * @property followingIndex
     * @private
     */
    get followingSlide() {
      return this.get('childSlides').objectAt(this.get('followingIndex'));
    }

    /**
     * @private
     * @property hasInterval
     * @type boolean
     */

    /**
     * This observer is the entry point for programmatically slide changing.
     *
     * @property indexObserver
     * @private
     */
    indexObserver() {
      this.send('toSlide', this.get('index'));
    }

    /**
     * @property indicators
     * @private
     */
    get indicators() {
      return [...Array(this.get('childSlides.length'))];
    }

    /**
     * If user is hovering its cursor on component.
     * This property is only manipulated when 'pauseOnMouseEnter' is true.
     *
     * @property isMouseHovering
     * @private
     * @type boolean
     */

    /**
     * Action called after the slide has changed.
     *
     * @event onSlideChanged
     * @param toIndex
     * @public
     */
    onSlideChanged(toIndex) {} // eslint-disable-line no-unused-vars

    /**
     * Do a presentation and calls itself to perform a cycle.
     *
     * @method cycle
     * @private
     */

    toSlide(toIndex) {
      if (this.get('currentIndex') === toIndex || this.get('shouldNotDoPresentation')) {
        return;
      }
      this.assignClassNameControls(toIndex);
      this.setFollowingIndex(toIndex);
      if (this.get('shouldRunAutomatically') === false || this.get('isMouseHovering')) {
        this.get('transitioner').perform();
      } else {
        this.get('cycle').perform();
      }
      this.get('onSlideChanged')(toIndex);
    }
    toNextSlide() {
      if (this.get('canTurnToRight')) {
        this.send('toSlide', this.get('currentIndex') + 1);
      }
    }
    toPrevSlide() {
      if (this.get('canTurnToLeft')) {
        this.send('toSlide', this.get('currentIndex') - 1);
      }
    }

    /**
     * Indicates what class names should be applicable to the current transition slides.
     *
     * @method assignClassNameControls
     * @private
     */
    assignClassNameControls(toIndex) {
      if (toIndex < this.get('currentIndex')) {
        this.set('directionalClassName', 'right');
        this.set('orderClassName', 'prev');
      } else {
        this.set('directionalClassName', 'left');
        this.set('orderClassName', 'next');
      }
    }

    /**
     * Initial page loading configuration.
     */
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.registerEvents();
      this.triggerChildSlidesObserver();
    }

    /**
     * mouseEnter() and mouseLeave() doesn't work with ember-native-dom-event-dispatcher.
     *
     * @method registerEvents
     * @private
     */
    registerEvents() {
      let self = this;
      this.element.addEventListener('mouseenter', function () {
        if (self.get('pauseOnMouseEnter')) {
          self.set('isMouseHovering', true);
          self.get('cycle').cancelAll();
          self.get('waitIntervalToInitCycle').cancelAll();
        }
      });
      this.element.addEventListener('mouseleave', function () {
        if (self.get('pauseOnMouseEnter') && (self.get('transitioner.last') !== null || self.get('waitIntervalToInitCycle.last') !== null)) {
          self.set('isMouseHovering', false);
          self.get('waitIntervalToInitCycle').perform();
        }
      });
    }
    keyDown(e) {
      let code = e.keyCode || e.which;
      if (this.get('keyboard') === false || /input|textarea/i.test(e.target.tagName)) {
        return;
      }
      switch (code) {
        case 37:
          this.send('toPrevSlide');
          break;
        case 39:
          this.send('toNextSlide');
          break;
        default:
          break;
      }
    }

    /**
     * Sets the following slide index within the lower and upper bounds.
     *
     * @method setFollowingIndex
     * @private
     */
    setFollowingIndex(toIndex) {
      let slidesLengthMinusOne = this.get('childSlides').length - 1;
      if (toIndex > slidesLengthMinusOne) {
        this.set('followingIndex', 0);
      } else if (toIndex < 0) {
        this.set('followingIndex', slidesLengthMinusOne);
      } else {
        this.set('followingIndex', toIndex);
      }
    }

    /**
     * Coordinates the correct slide movement direction.
     *
     * @method toAppropriateSlide
     * @private
     */
    toAppropriateSlide() {
      if (this.get('ltr')) {
        this.send('toNextSlide');
      } else {
        this.send('toPrevSlide');
      }
    }

    /**
     * @method triggerChildSlidesObserver
     * @private
     */
    triggerChildSlidesObserver() {
      this.get('childSlides');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "slideComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-carousel/slide';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "canTurnToLeft", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "canTurnToLeft"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canTurnToRight", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "canTurnToRight"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "childSlides", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "childSlidesObserver", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "childSlidesObserver"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "currentIndex", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.get('index');
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "currentSlide", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "currentSlide"), _class2.prototype), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "directionalClassName", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "followingIndex", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "followingSlide", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "followingSlide"), _class2.prototype), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "hasInterval", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "indexObserver", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "indexObserver"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "indicators", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "indicators"), _class2.prototype), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "isMouseHovering", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "presentationState", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "shouldNotDoPresentation", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "shouldRunAutomatically", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "autoPlay", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "wrap", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "index", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "interval", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 5000;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "keyboard", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "ltr", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "nextControlLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Next';
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "pauseOnMouseEnter", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "prevControlLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Previous';
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "showControls", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class2.prototype, "showIndicators", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class2.prototype, "transitionDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 600;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class2.prototype, "transition", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'slide';
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class2.prototype, "cycle", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class2.prototype, "transitioner", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class2.prototype, "waitIntervalToInitCycle", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "toSlide", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "toSlide"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toNextSlide", [_dec19], Object.getOwnPropertyDescriptor(_class2.prototype, "toNextSlide"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toPrevSlide", [_dec20], Object.getOwnPropertyDescriptor(_class2.prototype, "toPrevSlide"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = Carousel;
});