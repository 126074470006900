define("ember-steps/components/step-manager/step", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isActive}}
    {{yield
      (hash
        hasNext=this.hasNext
        hasPrevious=this.hasPrevious
      )
    }}
  {{else if (has-block "inverse")}}
    {{yield to="inverse"}}
  {{/if}}
  
  */
  {
    "id": "Acj8vSJZ",
    "block": "{\"symbols\":[\"&else\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"isActive\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,2,[[30,[36,1],null,[[\"hasNext\",\"hasPrevious\"],[[32,0,[\"hasNext\"]],[32,0,[\"hasPrevious\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"hash\"]}",
    "moduleName": "ember-steps/components/step-manager/step.hbs"
  });
  /**
   * ```hbs
   * <w.Step @name="first">
   *   <h1>First Step</h1>
   * </w.Step>
   * ```
   *
   * @class StepComponent
   * @yield {Hash} step Step Properties
   * @yield {boolean} step.hasPrevious Whether this step has a "previous" step
   * @yield {boolean} step.hasNext Whether this step has a "next" step
   */
  let StepComponent = (_dec = Ember._tracked, (_class = class StepComponent extends _component.default {
    /** Set directly by manager through JS **/

    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "transitions", _descriptor, this);
      this.args['register-step'](this);
    }
    willDestroy() {
      this.args['remove-step'](this);
      super.willDestroy(...arguments);
    }

    /**
     * @type {string|number|Symbol}
     * @private
     */
    get name() {
      const initialName = !Ember.isNone(this.args.name) ? this.args.name : Ember.guidFor(this);
      if (this._hasSetName) {
        (true && !(this._initialName === initialName) && Ember.assert('The `name` property should never change', this._initialName === initialName));
      }
      this._hasSetName = true;
      this._initialName = initialName;
      return initialName;
    }

    /**
     * Whether this state is currently the active one
     * @property {boolean} isActive
     * @private
     */
    get isActive() {
      return this.args.currentStep === this.name;
    }
    get hasNext() {
      return Ember.isPresent(this.transitions.pickNext(this.name));
    }
    get hasPrevious() {
      return Ember.isPresent(this.transitions.pickPrevious(this.name));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "transitions", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StepComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, StepComponent);
});