define("ember-svg-jar/inlined/codepen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 2l10 6.5v7L12 22 2 15.5v-7L12 2zM12 22v-6.5\"/><path d=\"M22 8.5l-10 7-10-7\"/><path d=\"M2 15.5l10-7 10 7M12 2v6.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "class": "feather feather-codepen"
    }
  };
  _exports.default = _default;
});