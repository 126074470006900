define("ember-light-table/components/lt-scaffolding-row", ["exports", "ember-light-table/templates/components/lt-scaffolding-row"], function (_exports, _ltScaffoldingRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['lt-scaffolding-row'],
    layout: _ltScaffoldingRow.default,
    tagName: 'tr'
  });
  _exports.default = _default;
});