define("ember-bootstrap/components/base/bs-progress/bar", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-progress/bar", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _bar, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
  
   Component for a single progress bar, see [Components.Progress](Components.Progress.html) for more examples.
  
   @class ProgressBar
   @namespace Components
   @extends Ember.Component
   @public
   */
  let ProgressBar = (_dec = (0, _component.layout)(_bar.default), _dec2 = (0, _component.classNames)('progress-bar'), _dec3 = (0, _component.classNameBindings)('progressBarStriped', 'typeClass'), _dec4 = (0, _component.attributeBindings)('ariaValuenow:aria-valuenow', 'ariaValuemin:aria-valuemin', 'ariaValuemax:aria-valuemax'), _dec5 = Ember.computed.readOnly('striped'), _dec6 = Ember.computed.readOnly('animate'), _dec7 = Ember.computed.readOnly('value'), _dec8 = Ember.computed.readOnly('minValue'), _dec9 = Ember.computed.readOnly('maxValue'), _dec10 = Ember.computed('value', 'minValue', 'maxValue').readOnly(), _dec11 = Ember.computed('percent', 'roundDigits').readOnly(), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class ProgressBar extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "ariaRole", 'progressbar');
      _initializerDefineProperty(this, "minValue", _descriptor, this);
      _initializerDefineProperty(this, "maxValue", _descriptor2, this);
      _initializerDefineProperty(this, "value", _descriptor3, this);
      _initializerDefineProperty(this, "showLabel", _descriptor4, this);
      _initializerDefineProperty(this, "striped", _descriptor5, this);
      _initializerDefineProperty(this, "animate", _descriptor6, this);
      _initializerDefineProperty(this, "roundDigits", _descriptor7, this);
      _initializerDefineProperty(this, "type", _descriptor8, this);
      _initializerDefineProperty(this, "progressBarStriped", _descriptor9, this);
      _initializerDefineProperty(this, "progressBarAnimate", _descriptor10, this);
      _initializerDefineProperty(this, "ariaValuenow", _descriptor11, this);
      _initializerDefineProperty(this, "ariaValuemin", _descriptor12, this);
      _initializerDefineProperty(this, "ariaValuemax", _descriptor13, this);
    }
    /**
     * The percentage of `value`
     *
     * @property percent
     * @type number
     * @protected
     * @readonly
     */
    get percent() {
      let value = parseFloat(this.get('value'));
      let minValue = parseFloat(this.get('minValue'));
      let maxValue = parseFloat(this.get('maxValue'));
      return Math.min(Math.max((value - minValue) / (maxValue - minValue), 0), 1) * 100;
    }

    /**
     * The percentage of `value`, rounded to `roundDigits` digits
     *
     * @property percentRounded
     * @type number
     * @protected
     * @readonly
     */
    get percentRounded() {
      let roundFactor = Math.pow(10, this.get('roundDigits'));
      return Math.round(this.get('percent') * roundFactor) / roundFactor;
    }

    /**
     * @method updateStyles
     * @return void
     * @private
     */
    updateStyles() {
      let percent = parseFloat(this.get('percent'));
      this.element.style.width = !isNaN(percent) ? `${percent}%` : '';
    }
    didInsertElement() {
      this.updateStyles();
    }
    didUpdateAttrs() {
      this.updateStyles();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "minValue", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "maxValue", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 100;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "value", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "showLabel", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "striped", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "animate", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "roundDigits", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "progressBarStriped", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "progressBarAnimate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "ariaValuenow", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "ariaValuemin", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "ariaValuemax", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "percent", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "percent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "percentRounded", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "percentRounded"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = ProgressBar;
});