define("ember-concurrency-test-waiter/with-test-waiter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withTestWaiter;
  let registered = false;
  let taskRunCounter = 0;

  // A function that, given a task property, will register it with the test
  // waiter so async test helpers will block anytime a task instance spawned
  // from that property is running.
  function withTestWaiter(taskProperty) {
    (true && !(Object.prototype.hasOwnProperty.call(taskProperty, 'taskFn')) && Ember.assert("withTestWaiter() will only work with ember-concurrency >=0.7.19 -- please upgrade", Object.prototype.hasOwnProperty.call(taskProperty, 'taskFn')));
    let originalTaskFn = taskProperty.taskFn;
    taskProperty.taskFn = function* () {
      if (Ember.testing && !registered) {
        Ember.Test.registerWaiter(() => taskRunCounter === 0); // eslint-disable-line ember/no-legacy-test-waiters
        registered = true;
      }
      taskRunCounter += 1;
      try {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        return yield* originalTaskFn.apply(this, args);
      } finally {
        taskRunCounter -= 1;
      }
    };
    return taskProperty;
  }
});