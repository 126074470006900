define("ember-svg-jar/inlined/dashboard-ports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.532 2.566c0 .872-.7 1.566-1.547 1.566A1.556 1.556 0 018.44 2.566c0-.872.7-1.566 1.546-1.566.847 0 1.547.694 1.547 1.566zm-1.05 2.517a2.562 2.562 0 002.05-2.517A2.556 2.556 0 009.985 0 2.556 2.556 0 007.44 2.566c0 1.246.88 2.284 2.049 2.517a.505.505 0 00-.003.049v1.21H6.59a.5.5 0 100 1h2.895v9.637c-3.432-.167-5.661-2.623-5.728-5.01l.78.785a.5.5 0 00.71-.704l-1.698-1.71-.355-.358-.355.357-1.698 1.711a.5.5 0 10.71.704l.905-.912v.017c0 3.147 2.945 6.13 7.102 6.13 2.082 0 3.97-.743 5.345-1.869 1.302-1.066 2.177-2.51 2.26-4.028l.656.662a.5.5 0 00.71-.704l-1.698-1.71-.354-.358-.355.357-1.698 1.711a.5.5 0 00.71.704l1.069-1.077a.498.498 0 00-.034.182c0 1.224-.695 2.5-1.9 3.488-1.061.87-2.49 1.486-4.084 1.616v-9.62h2.896a.5.5 0 000-1h-2.896V5.132c0-.017 0-.033-.002-.049z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "19",
      "height": "18",
      "viewBox": "0 0 19 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});