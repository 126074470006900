define("ember-bootstrap/templates/components/bs-form/element/control/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "36hCH0lY",
    "block": "{\"symbols\":[\"option\",\"index\",\"id\",\"@optionLabelPath\",\"&default\",\"@value\",\"@name\",\"@required\",\"@disabled\",\"@autofocus\",\"@tabindex\",\"@form\",\"@title\",\"&attrs\",\"@options\"],\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[32,15]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"form-check\",[30,[36,1],[[32,0,[\"inline\"]],\" form-check-inline\"],null]]]],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,0,[\"elementId\"]],\"-\",[32,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"input\"],[24,0,\"form-check-input\"],[16,1,[32,3]],[16,\"checked\",[30,[36,2],[[32,1],[32,6]],null]],[16,\"onclick\",[30,[36,3],[[32,0],[32,0,[\"onChange\"]],[32,1]],null]],[16,3,[32,7]],[16,\"required\",[32,8]],[16,\"disabled\",[32,9]],[16,\"autofocus\",[32,10]],[16,\"tabindex\",[32,11]],[16,\"form\",[32,12]],[16,\"title\",[32,13]],[17,14],[24,4,\"radio\"],[12],[13],[2,\"\\n      \"],[10,\"label\"],[15,\"for\",[32,3]],[14,0,\"form-check-label\"],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,5]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[18,5,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[[32,1],[32,4]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"get\",\"if\",\"bs-eq\",\"action\",\"concat\",\"let\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/control/radio.hbs"
  });
  _exports.default = _default;
});