define("ember-resize-modifier/modifiers/did-resize", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class DidResizeModifier extends _emberModifier.default {
    // Public API

    // Private API

    constructor() {
      super(...arguments);
      _defineProperty(this, "handler", null);
      _defineProperty(this, "options", {});
      if (!('ResizeObserver' in window)) {
        return;
      }
      if (!DidResizeModifier.observer) {
        DidResizeModifier.handlers = new WeakMap();
        DidResizeModifier.observer = new ResizeObserver((entries, observer) => {
          for (let entry of entries) {
            const handler = DidResizeModifier.handlers.get(entry.target);
            if (handler) handler(entry, observer);
          }
        });
      }
    }
    addHandler() {
      DidResizeModifier.handlers.set(this.element, this.handler);
    }
    removeHandler() {
      DidResizeModifier.handlers.delete(this.element);
    }
    observe() {
      if (DidResizeModifier.observer) {
        this.addHandler();
        DidResizeModifier.observer.observe(this.element, this.options);
      }
    }
    unobserve() {
      if (DidResizeModifier.observer) {
        DidResizeModifier.observer.unobserve(this.element);
        this.removeHandler();
      }
    }

    // Stop observing temporarily on update in case options have changed
    didUpdateArguments() {
      this.unobserve();
    }
    didReceiveArguments() {
      let [handler, options] = this.args.positional;

      // Save arguments for when we need them
      this.handler = handler;
      this.options = options || this.options;
      this.observe();
    }
    willRemove() {
      this.unobserve();
    }
  }
  _exports.default = DidResizeModifier;
  _defineProperty(DidResizeModifier, "observer", null);
  _defineProperty(DidResizeModifier, "handlers", null);
});