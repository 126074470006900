define("ember-bootstrap/templates/components/bs-form/element/layout/horizontal/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0gYFnVAV",
    "block": "{\"symbols\":[\"&default\",\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\"],\"statements\":[[10,\"div\"],[15,0,[31,[[32,0,[\"horizontalInputGridClass\"]],\" \",[32,0,[\"horizontalInputOffsetGridClass\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-check\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n    \"],[1,[30,[36,0],[[32,2]],null]],[2,\"\\n    \"],[1,[30,[36,0],[[32,3]],null]],[2,\"\\n    \"],[1,[30,[36,0],[[32,4]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/horizontal/checkbox.hbs"
  });
  _exports.default = _default;
});