define("ember-bootstrap/components/base/bs-modal/dialog", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-modal/dialog", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _dialog, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   Internal component for modal's markup and event handling. Should not be used directly.
  
   @class ModalDialog
   @namespace Components
   @extends Ember.Component
   @private
   */
  let ModalDialog = (_dec = (0, _component.layout)(_dialog.default), _dec2 = (0, _component.classNames)('modal'), _dec3 = (0, _component.classNameBindings)('fade'), _dec4 = (0, _component.attributeBindings)('tabindex', 'ariaLabelledby:aria-labelledby'), _dec5 = Ember.computed.readOnly('titleId'), _dec6 = Ember.computed('size').readOnly(), _dec7 = Ember._action, _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class ModalDialog extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "ariaRole", 'dialog');
      _defineProperty(this, "tabindex", '-1');
      _initializerDefineProperty(this, "ariaLabelledby", _descriptor, this);
      _initializerDefineProperty(this, "fade", _descriptor2, this);
      _initializerDefineProperty(this, "showModal", _descriptor3, this);
      _initializerDefineProperty(this, "inDom", _descriptor4, this);
      _initializerDefineProperty(this, "paddingLeft", _descriptor5, this);
      _initializerDefineProperty(this, "paddingRight", _descriptor6, this);
      _initializerDefineProperty(this, "keyboard", _descriptor7, this);
      _initializerDefineProperty(this, "size", _descriptor8, this);
      _initializerDefineProperty(this, "backdropClose", _descriptor9, this);
      _defineProperty(this, "titleId", null);
      _defineProperty(this, "ignoreBackdropClick", false);
      _defineProperty(this, "mouseDownElement", null);
    }
    /**
     * Name of the size class
     *
     * @property sizeClass
     * @type string
     * @readOnly
     * @private
     */
    get sizeClass() {
      let size = this.get('size');
      return Ember.isBlank(size) ? null : `modal-${size}`;
    }

    /**
     * The id of the `.modal-title` element
     *
     * @property titleId
     * @type string
     * @default null
     * @private
     */

    /**
     * Gets or sets the id of the title element for aria accessibility tags
     *
     * @method getSetTitleID
     * @private
     */
    getOrSetTitleId() {
      //Title element may be set by user so we have to try and find it to set the id
      const modalNode = this.get('element');
      let nodeId = null;
      if (modalNode) {
        const titleNode = modalNode.querySelector('.modal-title');
        if (titleNode) {
          //Get title id of .modal-title
          nodeId = titleNode.id;
          if (!nodeId) {
            //no title id so we set one
            nodeId = `${this.get('id')}-title`;
            titleNode.id = nodeId;
          }
        }
      }
      this.set('titleId', nodeId);
    }

    /**
     * If true clicking on the backdrop will be ignored and will not close modal.
     *
     * @property ignoreBackdropClick
     * @type boolean
     * @default false
     * @private
     */

    /**
     * Update the elements styles using CSSOM.
     *
     * This is necessary cause binding style attribute would require a
     * Content-Security-Policy `style-src 'unsafe-line'`.
     *
     * @method updateStyle
     * @return void
     * @private
     */
    updateStyles() {
      let {
        inDom,
        paddingLeft,
        paddingRight
      } = this.getProperties('inDom', 'paddingLeft', 'paddingRight');
      this.element.style.display = inDom ? 'block' : '';
      this.element.style.paddingLeft = paddingLeft || '';
      this.element.style.paddingRight = paddingRight || '';
    }
    initialFocus() {
      return this.element.querySelector('[autofocus]') || this.element;
    }

    /**
     * @event onClose
     * @public
     */
    onClose() {}
    keyDown(e) {
      let code = e.keyCode || e.which;
      if (code === 27 && this.get('keyboard')) {
        this.get('onClose')();
      }
    }
    _click(e) {
      if (this.ignoreBackdropClick) {
        this.set('ignoreBackdropClick', false);
        return;
      }
      if (e.target !== this.element || !this.get('backdropClose')) {
        return;
      }
      this.get('onClose')();
    }
    mouseDown(e) {
      this.set('mouseDownElement', e.target);
    }
    mouseUp(e) {
      if (this.mouseDownElement !== this.element && e.target === this.element) {
        this.set('ignoreBackdropClick', true);
      }
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      // Ember events use event delegation, but we need to add an `onclick` handler directly on the modal element for
      // iOS to allow clicking the div. So a `click(){}` method here won't work, we need to attach an event listener
      // directly to the element
      this.element.onclick = Ember.run.bind(this, this._click);
      this.getOrSetTitleId();
      this.updateStyles();
    }
    didUpdateAttrs() {
      this.updateStyles();
    }
    willDestroyElement() {
      this.element.onclick = null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ariaLabelledby", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "showModal", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "inDom", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "paddingLeft", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "paddingRight", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "keyboard", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "backdropClose", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "sizeClass", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "sizeClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "initialFocus", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "initialFocus"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = ModalDialog;
});