define("ember-svg-jar/inlined/dashboard-finance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.443 1a.5.5 0 00-.407.79L7.257 3.5h3.013l1.435-1.675a.5.5 0 00-.38-.825H6.444zm5.065 2.592l.957-1.116c.834-.973.142-2.476-1.14-2.476H6.444c-1.22 0-1.93 1.38-1.22 2.372l.86 1.204A5.502 5.502 0 001.5 9v6.5A2.5 2.5 0 004 18h9.5a2.5 2.5 0 002.5-2.5V9a5.502 5.502 0 00-4.492-5.408zM7 4.5A4.5 4.5 0 002.5 9v6.5A1.5 1.5 0 004 17h9.5a1.5 1.5 0 001.5-1.5V9a4.5 4.5 0 00-4.5-4.5H7z\" fill=\"#505152\"/><path d=\"M11 11.923c0 .52-.186.948-.557 1.286-.37.337-.907.545-1.607.624V15h-.698v-1.135c-.869-.014-1.581-.15-2.138-.409v-1.135c.301.15.653.278 1.056.382.402.1.763.152 1.082.156v-2.012l-.442-.167c-.574-.226-.994-.495-1.26-.807-.263-.315-.394-.704-.394-1.167 0-.495.187-.902.562-1.221.378-.32.89-.513 1.534-.581V6h.698v.888a5.67 5.67 0 012.022.44l-.383.985a5.522 5.522 0 00-1.639-.398v1.958l.4.156c.665.262 1.125.538 1.38.829.256.29.384.645.384 1.065zm-1.24.08a.665.665 0 00-.215-.51c-.14-.137-.376-.268-.709-.393v1.716c.616-.097.925-.368.925-.812zM7.277 8.696c0 .205.062.375.184.511.126.136.352.271.678.404V7.937c-.28.043-.494.13-.641.263a.64.64 0 00-.22.495z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});