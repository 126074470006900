define("ember-steps/-private/state-machine/-base", ["exports", "tracked-maps-and-sets", "ember-steps/-private/step-node"], function (_exports, _trackedMapsAndSets, _stepNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Keeps track of the order of the steps in the step manager, as well as
   * the current step.
   *
   * @class BaseStateMachine
   * @private
   * @hide
   */
  let BaseStateMachine = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class BaseStateMachine {
    /** @type {TrackedSet<StepNode>} **/

    /** @type {string} **/

    constructor(initialStep) {
      _initializerDefineProperty(this, "nodes", _descriptor, this);
      _initializerDefineProperty(this, "currentStep", _descriptor2, this);
      this.currentStep = initialStep;
    }
    get length() {
      return this.nodes.size;
    }
    get firstStepName() {
      const firstNode = this.nodeArray[0];
      return firstNode && firstNode.name;
    }
    get currentStepNode() {
      return this.nodeArray.find(stepNode => stepNode.name === this.currentStep);
    }
    get nodeArray() {
      return [...this.nodes];
    }
    addStep(stepComponent) {
      const node = new _stepNode.default(this, stepComponent);
      this.nodes.add(node);
      if (typeof this.currentStep === 'undefined') {
        this.currentStep = stepComponent.name;
      }
    }
    removeStep(stepComponent) {
      const node = this.nodeArray.find(step => step.component === stepComponent);
      (true && !(node) && Ember.assert(`Could not find a step with name: ${node.name}`, node));
      this.nodes.delete(node);
    }
    updateStepNode(name, field, value) {
      const node = this.nodeArray.find(node => node.name === name);
      (true && !(node) && Ember.assert(`"${name}" does not match an existing step`, node));
      node[field] = value;
    }
    pickNext() {
      throw new Error('Must implement method');
    }
    pickPrevious() {
      throw new Error('Must implement method');
    }
    activate(step) {
      const name = step instanceof _stepNode.default ? step.name : step;
      (true && !(!Ember.isNone(step)) && Ember.assert('No step name was provided', !Ember.isNone(step)));
      (true && !(this.nodeArray.map(node => node.name).includes(name)) && Ember.assert(`"${name}" does not match an existing step`, this.nodeArray.map(node => node.name).includes(name)));
      this.currentStep = name;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "nodes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedMapsAndSets.TrackedSet();
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentStep", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BaseStateMachine;
});