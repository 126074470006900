define("ember-modals-manager-internal/templates/components/emmi-modals-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NjcD5X+W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"modalIsOpened\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,0,[\"componentName\"]]],[[\"modalIsOpened\",\"options\",\"onConfirm\",\"onDecline\"],[[32,0,[\"modalIsOpened\"]],[32,0,[\"options\"]],[32,0,[\"confirm\"]],[32,0,[\"decline\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"component\",\"if\"]}",
    "moduleName": "ember-modals-manager-internal/templates/components/emmi-modals-container.hbs"
  });
  _exports.default = _default;
});