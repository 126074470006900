define("ember-svg-jar/inlined/dashboard-claims", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.5 16.5A1.5 1.5 0 003 18h10a1.5 1.5 0 001.5-1.5V9h-1v7.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V5.662a.5.5 0 01.135-.342l1.728-1.842.002-.003 2.202-2.32A.5.5 0 016.93 1H13a.5.5 0 01.5.5v3.767h1V1.5A1.5 1.5 0 0013 0H6.93a1.5 1.5 0 00-1.088.467L3.635 2.791l-1.73 1.845A1.5 1.5 0 001.5 5.662V16.5z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.5 1v4.5H2v-1h3.5V1h1zM15.461 5.68a.5.5 0 00-.692-.064l-7.04 5.668a.5.5 0 00-.149.197l-.734 1.762 1.885-.479a.5.5 0 00.19-.095l7.016-5.648a.5.5 0 00.065-.716l-.541-.626zm-1.32-.843a1.5 1.5 0 012.077.189l.54.626a1.5 1.5 0 01-.194 2.148l-7.016 5.648a1.5 1.5 0 01-.571.285l-3.823.97 1.503-3.606a1.5 1.5 0 01.444-.592l7.04-5.668z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});