define("ember-steps/-private/state-machine/circular", ["exports", "ember-steps/-private/state-machine/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Keeps track of the order of the steps in the step manager, as well as
   * the current step.
   *
   * @class CircularStateMachine
   * @extends BaseStateMachine
   * @private
   * @hide
   */
  class CircularStateMachine extends _base.default {
    pickNext() {
      let currentStep = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.currentStep;
      const currentIndex = this.nodeArray.map(node => node.name).indexOf(currentStep);
      const nextValue = this.nodeArray[currentIndex + 1];
      if (nextValue) {
        return nextValue.name;
      }
      const firstObject = this.nodeArray[0];
      if (firstObject) {
        return firstObject.name;
      }
      return undefined;
    }
    pickPrevious() {
      let currentStep = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.currentStep;
      const currentIndex = this.nodeArray.map(node => node.name).indexOf(currentStep);
      const previousValue = this.nodeArray[currentIndex - 1];
      if (previousValue) {
        return previousValue.name;
      }
      const lastIndex = this.length - 1;
      const lastObject = this.nodeArray[lastIndex];
      if (lastObject) {
        return lastObject.name;
      }
      return undefined;
    }
  }
  _exports.default = CircularStateMachine;
});