define("ember-local-storage/mixins/array", ["exports", "ember-local-storage/mixins/storage", "ember-local-storage/helpers/utils"], function (_exports, _storage, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create(_storage.default, {
    _initialContent: Ember.A(),
    _clear() {
      Ember.set(this, 'content', Ember.A());
    },
    replaceContent: _utils.save,
    reset: _utils.save
  });
  _exports.default = _default;
});