define("ember-drag-sort/templates/components/drag-sort-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{yield}}
  
  */
  {
    "id": "ydRemjup",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-drag-sort/templates/components/drag-sort-item.hbs"
  });
  _exports.default = _default;
});