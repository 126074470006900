define("ember-bootstrap/templates/components/bs-progress/bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eIc7uGnE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[32,0,[\"percentRounded\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,0,[\"percentRounded\"]]],[2,\"%\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[18,1,[[32,0,[\"percentRounded\"]]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[1,[32,0,[\"percentRounded\"]]],[2,\"%\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"showLabel\"]}",
    "moduleName": "ember-bootstrap/templates/components/bs-progress/bar.hbs"
  });
  _exports.default = _default;
});