define("ember-element-helper/helpers/-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function UNINITIALIZED() {}
  const DynamicElement = Ember.Component.extend();
  const DynamicElementAlt = Ember.Component.extend();
  var _default = Ember.Helper.extend({
    init() {
      this._super(...arguments);
      this.tagName = UNINITIALIZED;
      this.componentClass = null;
    },
    compute(params, hash) {
      (true && !(params.length === 1) && Ember.assert('The `element` helper takes a single positional argument', params.length === 1));
      (true && !(Object.keys(hash).length === 0) && Ember.assert('The `element` helper does not take any named arguments', Object.keys(hash).length === 0));
      let tagName = params[0];
      if (tagName !== this.tagName) {
        this.tagName = tagName;
        if (typeof tagName === 'string') {
          // return a different component name to force a teardown
          if (this.componentClass === DynamicElement) {
            this.componentClass = DynamicElementAlt;
          } else {
            this.componentClass = DynamicElement;
          }
        } else {
          this.componentClass = null;
          Ember.runInDebug(() => {
            let message = 'The argument passed to the `element` helper must be a string';
            try {
              message += ` (you passed \`${tagName}\`)`;
            } catch (e) {
              // ignore
            }
            (true && !(tagName === undefined || tagName === null) && Ember.assert(message, tagName === undefined || tagName === null));
          });
        }
      }
      return this.componentClass;
    }
  });
  _exports.default = _default;
});