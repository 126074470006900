define("ember-model-select/components/model-select/options", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul role="listbox" ...attributes>
    <li>
      <PowerSelect::Options
        @loadingMessage={{@loadingMessage}}
        @select={{@select}}
        @options={{@options}}
        @groupIndex={{@groupIndex}}
        @optionsComponent={{@optionsComponent}}
        @extra={{@extra}}
        @highlightOnHover={{@highlightOnHover}}
        @groupComponent={{@groupComponent}}
        as |option select|
      >
        {{yield option select}}
      </PowerSelect::Options>
    </li>
  
    {{#if this.showLoader}}
      <li>
        <InfinityLoader
          @infinityModel={{@infiniteModel}}
          @hideOnInfinity={{true}}
          @scrollable={{concat "#ember-basic-dropdown-content-" @select.uniqueId}}
        >
          <ModelSelect::Spinner />
        </InfinityLoader>
      </li>
    {{/if}}
  </ul>
  
  */
  {
    "id": "OoyyQnku",
    "block": "{\"symbols\":[\"option\",\"select\",\"@infiniteModel\",\"@select\",\"&attrs\",\"@loadingMessage\",\"@options\",\"@groupIndex\",\"@optionsComponent\",\"@extra\",\"@highlightOnHover\",\"@groupComponent\",\"&default\"],\"statements\":[[11,\"ul\"],[24,\"role\",\"listbox\"],[17,5],[12],[2,\"\\n  \"],[10,\"li\"],[12],[2,\"\\n    \"],[8,\"power-select/options\",[],[[\"@loadingMessage\",\"@select\",\"@options\",\"@groupIndex\",\"@optionsComponent\",\"@extra\",\"@highlightOnHover\",\"@groupComponent\"],[[32,6],[32,4],[32,7],[32,8],[32,9],[32,10],[32,11],[32,12]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,13,[[32,1],[32,2]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"showLoader\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"\\n      \"],[8,\"infinity-loader\",[],[[\"@infinityModel\",\"@hideOnInfinity\",\"@scrollable\"],[[32,3],true,[30,[36,0],[\"#ember-basic-dropdown-content-\",[32,4,[\"uniqueId\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"model-select/spinner\",[],[[],[]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "moduleName": "ember-model-select/components/model-select/options.hbs"
  });
  let OptionsComponent = (_dec = Ember.computed('args.{infiniteScroll,infiniteModel,select.loading}'), (_class = class OptionsComponent extends _component.default {
    get showLoader() {
      return this.args.infiniteScroll && this.args.infiniteModel && !this.args.select.loading;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "showLoader", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "showLoader"), _class.prototype)), _class));
  _exports.default = OptionsComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OptionsComponent);
});