define("ember-bootstrap-modals-manager/services/modals-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * @class ModalsManager
   */
  let ModalsManager = (_dec = Ember._tracked, (_class = class ModalsManager extends Ember.Service {
    constructor() {
      super(...arguments);
      _defineProperty(this, "modalIsOpened", false);
      _defineProperty(this, "modalsContainerPath", 'ebmm-modals-container');
      _defineProperty(this, "modalDefer", null);
      _defineProperty(this, "defaultOptions", {
        title: ' ',
        body: ' ',
        footer: ' ',
        confirm: 'Yes',
        decline: 'No',
        cancel: 'Cancel'
      });
      _initializerDefineProperty(this, "options", _descriptor, this);
      _defineProperty(this, "componentName", null);
    }
    /**
     * Shows custom modal
     *
     * @method show
     * @param {string} componentName component's name with custom modal
     * @param {object} options
     * @return {RSVP.Promise}
     */
    show(componentName, options) {
      (true && !(!this.modalIsOpened) && Ember.assert('Only one modal may be opened in the same time!', !this.modalIsOpened));
      const opts = Object.assign({}, this.defaultOptions, options);
      Ember.set(this, 'modalIsOpened', true);
      Ember.set(this, 'options', opts);
      Ember.set(this, 'componentName', componentName);
      const modalDefer = Ember.RSVP.defer();
      Ember.set(this, 'modalDefer', modalDefer);
      return modalDefer.promise;
    }

    /**
     * Shows `alert`-modal
     *
     * @method alert
     * @param {object} options
     * @return {RSVP.Promise}
     */
    alert(options) {
      return this.show(`${this.modalsContainerPath}/alert`, options);
    }

    /**
     * Shows `confirm`-modal
     *
     * @method confirm
     * @param {object} options
     * @return {RSVP.Promise}
     */
    confirm(options) {
      return this.show(`${this.modalsContainerPath}/confirm`, options);
    }

    /**
     * Shows `prompt`-modal
     *
     * @method prompt
     * @param {object} options
     * @return {RSVP.Promise}
     */
    prompt(options) {
      return this.show(`${this.modalsContainerPath}/prompt`, options);
    }

    /**
     * Shows `prompt-confirm`-modal
     *
     * @method promptConfirm
     * @param {object} options
     * @return {RSVP.Promise}
     */
    promptConfirm(options) {
      (true && !(!!options.promptValue) && Ember.assert('"options.promptValue" must be defined and not empty', !!options.promptValue));
      return this.show(`${this.modalsContainerPath}/prompt-confirm`, options);
    }

    /**
     * Show `check-confirm`-modal
     *
     * @method checkConfirm
     * @param {object} options
     * @return {RSVP.Promise}
     */
    checkConfirm(options) {
      return this.show(`${this.modalsContainerPath}/check-confirm`, options);
    }

    /**
     * Shows `progress`-modal
     *
     * @method progress
     * @param {object} options
     * @return {RSVP.Promise}
     */
    progress(options) {
      (true && !(options && Ember.isArray(options.promises)) && Ember.assert('`options.promises` must be an array', options && Ember.isArray(options.promises)));
      return this.show(`${this.modalsContainerPath}/progress`, options);
    }

    /**
     * Shows `process`-modal
     *
     * @method process
     * @param {object} options
     * @return {RSVP.Promise}
     */
    process(options) {
      (true && !(options && options.process) && Ember.assert('`options.process` must be defined', options && options.process));
      return this.show(`${this.modalsContainerPath}/process`, options);
    }
    onConfirmClick(v) {
      var _this$modalDefer;
      Ember.set(this, 'modalIsOpened', false);
      (_this$modalDefer = this.modalDefer) === null || _this$modalDefer === void 0 ? void 0 : _this$modalDefer.resolve(v);
      this.clearOptions();
    }
    onDeclineClick(v) {
      var _this$modalDefer2;
      Ember.set(this, 'modalIsOpened', false);
      (_this$modalDefer2 = this.modalDefer) === null || _this$modalDefer2 === void 0 ? void 0 : _this$modalDefer2.reject(v);
      this.clearOptions();
    }
    clearOptions() {
      Ember.set(this, 'options', {});
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "options", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  })), _class));
  _exports.default = ModalsManager;
});