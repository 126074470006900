define("ember-svg-jar/inlined/dashboard-agents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 16a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2H2zM14.833 5H16a1 1 0 011 1v8a1 1 0 01-1 1h-1.167V5zm-1 0v10H4.167V5h9.666zM3.167 5H2a1 1 0 00-1 1v8a1 1 0 001 1h1.167V5zM7 2.5a.5.5 0 00-.5.5v1h-1V3A1.5 1.5 0 017 1.5h4A1.5 1.5 0 0112.5 3v1h-1V3a.5.5 0 00-.5-.5H7z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});