define("ember-toggle/components/x-toggle-label/component", ["exports", "ember-toggle/components/x-toggle-label/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    for: Ember.computed.readOnly('switchId'),
    labelType: Ember.computed('type', function () {
      let type = this.get('type');
      return `${type}-label`;
    }),
    type: Ember.computed('value', {
      get() {
        return this.get('value') ? 'on' : 'off';
      }
    }),
    actions: {
      clickLabel(e) {
        e.stopPropagation();
        e.preventDefault();
        this.sendToggle(this.get('value'));
      }
    }
  });
  _exports.default = _default;
});