define("ember-steps/components/step-manager", ["exports", "@glimmer/component", "ember-steps/-private/state-machine/circular", "ember-steps/-private/state-machine/linear", "ember-steps/-private/step-node"], function (_exports, _component, _circular, _linear, _stepNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield
    (hash
      Step=(component "step-manager/step"
        register-step=this.registerStepComponent
        remove-step=this.removeStepComponent
        update-step-node=this.updateStepNode
        currentStep=this.currentStep
        transitions=this.transitions
      )
      hasNextStep=this.hasNextStep
      hasPreviousStep=this.hasPreviousStep
      currentStep=this.currentStep
      steps=this.transitions.nodeArray
      transition-to=this.transitionTo
      transition-to-next=this.transitionToNext
      transition-to-previous=this.transitionToPrevious
    )
  }}
  
  */
  {
    "id": "nHpeQ48D",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"Step\",\"hasNextStep\",\"hasPreviousStep\",\"currentStep\",\"steps\",\"transition-to\",\"transition-to-next\",\"transition-to-previous\"],[[30,[36,0],[\"step-manager/step\"],[[\"register-step\",\"remove-step\",\"update-step-node\",\"currentStep\",\"transitions\"],[[32,0,[\"registerStepComponent\"]],[32,0,[\"removeStepComponent\"]],[32,0,[\"updateStepNode\"]],[32,0,[\"currentStep\"]],[32,0,[\"transitions\"]]]]],[32,0,[\"hasNextStep\"]],[32,0,[\"hasPreviousStep\"]],[32,0,[\"currentStep\"]],[32,0,[\"transitions\",\"nodeArray\"]],[32,0,[\"transitionTo\"]],[32,0,[\"transitionToNext\"]],[32,0,[\"transitionToPrevious\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "ember-steps/components/step-manager.hbs"
  });
  /**
   * ```hbs
   * <StepManager as |w|>
   *   <w.Step @name="first">
   *     <h1>First Step</h1>
   *   </w.Step>
   *   <w.Step @name="second">
   *     <h1>Second Step</h1>
   *   </w.Step>
   * </StepManager>
   * ```
   *
   * @class StepManagerComponent
   * @yield {Hash} w Wizard Properties
   * @yield {StepComponent} w.Step The component to create steps
   * @yield {boolean} w.hasNextStep Whether or not the current step has a "next" step
   * @yield {boolean} w.hasPreviousStep Whether or not the current step has a "previous" step
   * @yield {string} w.currentStep Reflects the name of the active step
   * @yield {Array<StepNode>} w.steps The steps registered to the step manager
   * @yield {Action} w.transition-to Transition to a step by name
   * @yield {Action} w.transition-to-next Transition to the "next" step
   * @yield {Action} w.transition-to-previous Transition to the "previous" step
   */
  let StepManagerComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class StepManagerComponent extends _component.default {
    /** @type {BaseStateMachine} state machine for transitions */

    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "transitions", _descriptor, this);
      const {
        initialStep,
        currentStep
      } = this.args;
      this._watchCurrentStep = Ember.isPresent(currentStep);
      const startingStep = Ember.isNone(initialStep) ? currentStep : initialStep;
      const StateMachine = this.linear ? _linear.default : _circular.default;
      this.transitions = new StateMachine(startingStep);
      Ember.run.schedule('afterRender', this, function () {
        this._initialRegistrationComplete = true;
      });
    }

    /**
     * Whether to use a "Circular" or "Linear" state machine
     * @type {boolean}
     * @private
     */
    get linear() {
      return Ember.isPresent(this.args.linear) ? this.args.linear : true;
    }

    /**
     * Whether or not the current step has a "next" step
     * @type {boolean}
     */
    get hasNextStep() {
      return !Ember.isNone(this.transitions.pickNext());
    }

    /**
     * Whether or not the current step has a "previous" step
     * @type {boolean}
     */
    get hasPreviousStep() {
      return !Ember.isNone(this.transitions.pickPrevious());
    }

    /**
     * Reflects the name of the active step
     * @type {string}
     */
    get currentStep() {
      const newStep = typeof this.args.currentStep !== 'undefined' ? this.args.currentStep : this.transitions.firstStepName;
      const {
        currentStep
      } = this.transitions;
      if (this._watchCurrentStep && this._initialRegistrationComplete && newStep !== currentStep) {
        Ember.run.schedule('actions', this, function () {
          this.transitionTo(newStep);
        });
      }
      return this.transitions.currentStep;
    }
    registerStepComponent(stepComponent) {
      stepComponent.transitions = this.transitions;
      Ember.run.schedule('actions', () => {
        this.transitions.addStep(stepComponent);
      });
    }
    removeStepComponent(stepComponent) {
      Ember.run.schedule('actions', () => {
        this.transitions.removeStep(stepComponent);
      });
    }
    updateStepNode(stepComponent, field, value) {
      const name = stepComponent.name;
      this.transitions.updateStepNode(name, field, value);
    }
    transitionTo(to) {
      const destination = to instanceof _stepNode.default ? to.name : to;
      if (to !== this.transitions.currentStep) {
        this.transitions.activate(destination);
        if (this.args.onTransition) {
          this.args.onTransition(destination);
        }
      }
    }
    transitionToNext() {
      const to = this.transitions.pickNext();
      (true && !(!Ember.isNone(to)) && Ember.assert('There is no next step', !Ember.isNone(to)));
      this.transitionTo(to);
    }
    transitionToPrevious() {
      const to = this.transitions.pickPrevious();
      (true && !(!Ember.isNone(to)) && Ember.assert('There is no previous step', !Ember.isNone(to)));
      this.transitionTo(to);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "transitions", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "registerStepComponent", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "registerStepComponent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeStepComponent", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeStepComponent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStepNode", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateStepNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionTo", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "transitionTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToNext", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToNext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToPrevious", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToPrevious"), _class.prototype)), _class));
  _exports.default = StepManagerComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, StepManagerComponent);
});