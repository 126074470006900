define("ember-modals-manager-internal/components/emmi-modals-container/alert", ["exports", "ember-modals-manager-internal/components/emmi-modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Alert-modal
   *
   * @class AlertModal
   * @namespace Components
   * @extends Components.BaseModal
   */
  class AlertModal extends _base.default {}
  _exports.default = AlertModal;
});