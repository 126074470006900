define("ember-bootstrap/templates/components/bs-tooltip/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "d1MIEOGV",
    "block": "{\"symbols\":[\"@destinationElement\",\"@id\",\"&attrs\",\"&default\"],\"statements\":[[8,\"ember-popper\",[[17,3]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@id\",\"@class\"],[[32,0,[\"ariaRole\"]],[32,0,[\"placement\"]],[32,0,[\"renderInPlace\"]],[32,0,[\"popperTarget\"]],[32,0,[\"popperModifiers\"]],[32,1],[32,0,[\"updatePlacement\"]],[32,0,[\"updatePlacement\"]],[32,2],[32,0,[\"popperClass\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[32,0,[\"arrowClass\"]]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tooltip-inner\"],[12],[2,\"\\n    \"],[18,4,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-bootstrap/templates/components/bs-tooltip/element.hbs"
  });
  _exports.default = _default;
});