define("ember-svg-jar/inlined/warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.77 5.087c.746-1.371 2.706-1.397 3.488-.046l-1.73 1.002L5.681 31.5h28.584L19.527 6.043l1.731-1.002 14.738 25.457c.772 1.333-.19 3.002-1.73 3.002H5.682c-1.517 0-2.482-1.623-1.757-2.956L17.77 5.087z\" fill=\"#1E1E1E\"/><path d=\"M20.94 24.23h-1.837l-.202-9.027h2.25l-.21 9.027zm-2.1 2.76c0-.335.108-.61.325-.827.217-.223.513-.334.888-.334s.67.111.887.334c.217.217.326.492.326.826 0 .322-.106.592-.317.809-.21.217-.51.325-.896.325-.387 0-.686-.108-.897-.325a1.114 1.114 0 01-.316-.809z\" fill=\"#1E1E1E\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});