define("ember-local-storage/mixins/adapters/import-export", ["exports", "ember-local-storage/helpers/import-export"], function (_exports, _importExport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    importData(store, content, options) {
      return (0, _importExport.importData)(store, content, options);
    },
    exportData(store, types, options) {
      return (0, _importExport.exportData)(store, types, options);
    }
  });
  _exports.default = _default;
});