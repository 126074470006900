define("ember-loading/services/loading", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  function parseArgs() {
    let length = arguments.length;
    let args;
    let method;
    let target;
    if (length === 1) {
      target = null;
      method = arguments[0];
    } else if (length > 1) {
      let argsIndex = 2;
      let methodOrTarget = arguments[0];
      let methodOrArgs = arguments[1];
      let type = typeof methodOrArgs;
      if (type === 'function') {
        target = methodOrTarget;
        method = methodOrArgs;
      } else if (methodOrTarget !== null && type === 'string' && methodOrArgs in methodOrTarget) {
        target = methodOrTarget;
        method = target[methodOrArgs];
      } else if (typeof methodOrTarget === 'function') {
        argsIndex = 1;
        target = null;
        method = methodOrTarget;
      }
      if (length > argsIndex) {
        let len = length - argsIndex;
        args = new Array(len);
        for (let i = 0; i < len; i++) {
          args[i] = arguments[i + argsIndex];
        }
      }
    }
    return [target, method, args];
  }
  let LoadingService = (_dec = Ember.inject.service, _dec2 = Ember.computed.readOnly('_runJob.isRunning'), _dec3 = Ember.computed('isLoading', 'preDelayTask.isRunning', 'postDelayTask.isRunning'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)(function* () {
    let [target, method, args] = parseArgs(...arguments);
    return yield method.apply(target, args);
  }), _dec7 = (0, _emberConcurrency.task)(function* (delay) {
    yield (0, _emberConcurrency.timeout)(delay);
  }).restartable(), _dec8 = (0, _emberConcurrency.task)(function* (delay) {
    yield (0, _emberConcurrency.timeout)(delay);
  }).restartable(), (_class = class LoadingService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      this.postDelay = 0;
      this.preDelay = 0;
      this.watchTransitions = true;
      _initializerDefineProperty(this, "isLoading", _descriptor2, this);
      this._routerTransitionDeferred = void 0;
      _initializerDefineProperty(this, "_runJob", _descriptor3, this);
      _initializerDefineProperty(this, "preDelayTask", _descriptor4, this);
      _initializerDefineProperty(this, "postDelayTask", _descriptor5, this);
    }
    get showLoading() {
      // @ts-ignore
      return !this.preDelayTask.isRunning && (this.isLoading || this.postDelayTask.isRunning);
    }
    _routeWillChange() {
      let deferred = Ember.RSVP.defer();
      if (this._routerTransitionDeferred) {
        this._routerTransitionDeferred.resolve();
      }
      this.set('_routerTransitionDeferred', deferred);
      this.run(() => deferred.promise);
    }
    _routeDidChange() {
      if (this._routerTransitionDeferred) {
        this._routerTransitionDeferred.resolve();
        this.set('_routerTransitionDeferred', undefined);
      }
    }
    init() {
      super.init();
      let config = Ember.getOwner(this).resolveRegistration('config:environment')['ember-loading'];
      if (config) {
        this.preDelay = config.preDelay || 0;
        this.postDelay = config.postDelay || 0;
        this.watchTransitions = config.watchTransitions === false ? false : true;
      }
      if (this.watchTransitions) {
        this.router.on('routeWillChange', this._routeWillChange);
        this.router.on('routeDidChange', this._routeDidChange);
      }
    }
    willDestroy() {
      super.willDestroy();
      if (this.watchTransitions) {
        this.router.off('routeWillChange', this._routeWillChange);
        this.router.off('routeDidChange', this._routeDidChange);
      }
    }

    // @todo Revisit this stronger typing when https://github.com/typed-ember/ember-cli-typescript/issues/590 is resolved
    //
    // run<T, P1, P2, P3, P4, P5, P6, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6): Promise<R>;
    // run<T, P1, P2, P3, P4, P5, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4, p5: P5) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5): Promise<R>;
    // run<T, P1, P2, P3, P4, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3, p4: P4) => R) | keyof T, p1: P1, p2: P2, p3: P3, p4: P4): Promise<R>;
    // run<T, P1, P2, P3, R>(target: T, fn: ((p1: P1, p2: P2, p3: P3) => R) | keyof T, p1: P1, p2: P2, p3: P3): Promise<R>;
    // run<T, P1, P2, R>(target: T, fn: ((p1: P1, p2: P2) => R) | keyof T, p1: P1, p2: P2): Promise<R>;
    // run<T, P1, R>(target: T, fn: ((p1: P1) => R) | keyof T, p1: P1): Promise<R>;
    // run<T, R>(target: T, fn: (() => R) | keyof T): Promise<R>
    // run<P1, P2, P3, P4, P5, P6, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6) => R, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5, p6: P6): Promise<R>;
    // run<P1, P2, P3, P4, P5, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4, p5: P5) => R, p1: P1, p2: P2, p3: P3, p4: P4, p5: P5): Promise<R>;
    // run<P1, P2, P3, P4, R>(fn: (p1: P1, p2: P2, p3: P3, p4: P4) => R, p1: P1, p2: P2, p3: P3, p4: P4): Promise<R>;
    // run<P1, P2, P3, R>(fn: (p1: P1, p2: P2, p3: P3) => R, p1: P1, p2: P2, p3: P3): Promise<R>;
    // run<P1, P2, R>(fn: (p1: P1, p2: P2) => R, p1: P1, p2: P2): Promise<R>;
    // run<P1, R>(fn: (p1: P1) => R, p1: P1): Promise<R>;
    // run<R>(fn: () => R): Promise<R>;
    // run<T, R>(target: T, fn: ((...args: any[]) => R) | keyof T, ...args: any[]): Promise<R>;
    // run<T, R>(target: T, fn: (() => R) | keyof T): Promise<R>;
    // run<R>(fn: (...args: any[]) => R, ...args: any[]): Promise<R>;
    // run<R>(fn: () => R): Promise<R>;
    async run() {
      if (this.preDelay > 0) {
        // @ts-ignore
        this.preDelayTask.perform(this.preDelay);
      }
      let result = await this._runJob.perform(...arguments);
      if (this.postDelay > 0) {
        // @ts-ignore
        this.postDelayTask.perform(this.postDelay);
      }
      return result;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showLoading", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "showLoading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_routeWillChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_routeWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_routeDidChange", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_routeDidChange"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_runJob", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "preDelayTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "postDelayTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
  _exports.default = LoadingService;
});