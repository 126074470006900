define("ember-bootstrap/components/base/bs-tab", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-tab", "ember-bootstrap/mixins/component-parent", "ember-bootstrap/components/bs-tab/pane", "ember-bootstrap/utils/cp/listen-to", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _bsTab, _componentParent, _pane, _listenTo, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
    Tab component for dynamic tab functionality that mimics the behaviour of Bootstrap's tab.js plugin,
    see http://getbootstrap.com/javascript/#tabs
  
    ### Usage
  
    Just nest any number of yielded [Components.TabPane](Components.TabPane.html) components that hold the tab content.
    The tab navigation is automatically generated from the tab panes' `title` property:
  
    ```hbs
    <BsTab as |tab|>
      <tab.pane @title="Tab 1">
        <p> ... </p>
      </tab.pane>
      <tab.pane @title="Tab 2">
        <p> ... </p>
      </tab.pane>
    </BsTab>
    ```
  
    ### Groupable (dropdown) tabs
  
    Bootstrap's support for dropdown menus as tab navigation is mimiced by the use of the `groupTitle` property.
    All panes with the same `groupTitle` will be put inside the menu of a [Components.Dropdown](Components.Dropdown.html)
    component with `groupTitle` being the dropdown's title:
  
    ```hbs
    <BsTab as |tab|>
      <tab.pane @title="Tab 1">
        <p> ... </p>
      </tab.pane>
      <tab.pane @title="Tab 2">
        <p> ... </p>
      </tab.pane>
      <tab.pane @title="Tab 3" @groupTitle="Dropdown">
        <p> ... </p>
      </tab.pane>
      <tab.pane @title="Tab 4" @groupTitle="Dropdown">
        <p> ... </p>
      </tab.pane>
    </BsTab>
    ```
  
    ### Custom tabs
  
    When having the tab pane's `title` as the tab navigation title is not sufficient, for example because you want to
    integrate some other dynamic content, maybe even other components in the tab navigation item, then you have to setup
    your navigation by yourself.
  
    Set `customTabs` to true to deactivate the automatic tab navigation generation. Then setup your navigation, probably
    using a [Components.Nav](Components.Nav.html) component. The tab component yields the `activeId` property as well as
    its `select` action, which you would have to use to manually set the `active` state of the navigation items and to
    trigger the selection of the different tab panes, using their ids:
  
    ```hbs
    <BsTab @customTabs={{true}} as |tab|>
      <BsNav @type="tabs" as |nav|>
        <nav.item @active={{bs-eq Tab.activeId "pane1"}}><a href="#pane1" role="tab" onclick={{action Tab.select "pane1"}}>Tab 1</a></nav.item>
        <nav.item @active={{bs-eq Tab.activeId "pane2"}}><a href="#pane2" role="tab" onclick={{action Tab.select "pane2"}}>Tab 2 <span class="badge">{{badge}}</span></a></nav.item>
      </BsNav>
      <div class="tab-content">
        <tab.pane @id="pane1" @title="Tab 1">
          <p> ... </p>
        </tab.pane>
        <tab.pane @id="pane2" @title="Tab 2">
          <p> ... </p>
        </tab.pane>
      </div>
    </BsTab>
    ```
  
    Note that the `bs-eq` helper used in the example above is a private helper, which is not guaranteed to be available for
    the future. Better use the corresponding `eq` helper of the
    [ember-truth-helpers](https://github.com/jmurphyau/ember-truth-helpers) addon for example!
  
    ### Routable tabs
  
    The tab component purpose is to have panes of content, that are all in DOM at the same time and that are activated and
    deactivated dynamically, just as the  original Bootstrap implementation.
  
    If you want to have the content delivered through individual sub routes, just use
    the [Components.Nav](Components.Nav.html) component and an `{{outlet}}` that show the nested routes' content:
  
    ```hbs
    <div>
      <BsNav @type="tabs" as |nav|>
        <nav.item>
          <nav.linkTo @route="tabs.index">Tab 1</nav.linkTo>
        </nav.item>
        <nav.item>
          <nav.linkTo @route="tabs.other">Tab 3</nav.linkTo>
        </nav.item>
      </BsNav>
    </div>
    ```
  
    @class Tab
    @namespace Components
    @extends Ember.Component
    @uses Mixins.ComponentParent
    @public
  */
  let Tab = (_dec = (0, _component.layout)(_bsTab.default), _dec2 = Ember.computed.oneWay('childPanes.firstObject.elementId'), _dec3 = (0, _listenTo.default)('activeId'), _dec4 = Ember.computed.filter('children', function (view) {
    return view instanceof _pane.default;
  }), _dec5 = Ember.computed('childPanes.@each.{elementId,title,group}'), _dec6 = Ember._action, _dec(_class = (_class2 = class Tab extends Ember.Component.extend(_componentParent.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "type", _descriptor, this);
      _initializerDefineProperty(this, "paneComponent", _descriptor2, this);
      _initializerDefineProperty(this, "navComponent", _descriptor3, this);
      _initializerDefineProperty(this, "customTabs", _descriptor4, this);
      _initializerDefineProperty(this, "activeId", _descriptor5, this);
      _initializerDefineProperty(this, "isActiveId", _descriptor6, this);
      _initializerDefineProperty(this, "fade", _descriptor7, this);
      _initializerDefineProperty(this, "fadeDuration", _descriptor8, this);
      _initializerDefineProperty(this, "childPanes", _descriptor9, this);
    }
    /**
     * This action is called when switching the active tab, with the new and previous pane id
     *
     * You can return false to prevent changing the active tab automatically, and do that in your action by
     * setting `activeId`.
     *
     * @event onChange
     * @public
     */
    onChange() {}

    /**
     * All `TabPane` child components
     *
     * @property childPanes
     * @type array
     * @readonly
     * @private
     */

    /**
     * Array of objects that define the tab structure
     *
     * @property navItems
     * @type array
     * @readonly
     * @private
     */
    get navItems() {
      let items = Ember.A();
      this.get('childPanes').forEach(pane => {
        let groupTitle = pane.get('groupTitle');
        let item = pane.getProperties('elementId', 'title');
        if (Ember.isPresent(groupTitle)) {
          let group = items.findBy('groupTitle', groupTitle);
          if (group) {
            group.children.push(item);
            group.childIds.push(item.elementId);
          } else {
            items.push({
              isGroup: true,
              groupTitle,
              children: Ember.A([item]),
              childIds: Ember.A([item.elementId])
            });
          }
        } else {
          items.push(item);
        }
      });
      return items;
    }
    select(id) {
      let previous = this.get('isActiveId');
      if (this.get('onChange')(id, previous) !== false) {
        // change active tab when `onChange` does not return false
        this.set('isActiveId', id);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'tabs';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "paneComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-tab/pane';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "navComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-nav';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "customTabs", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "activeId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isActiveId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "fade", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "fadeDuration", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 150;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "childPanes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "navItems", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "navItems"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "select", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "select"), _class2.prototype)), _class2)) || _class);
  _exports.default = Tab;
});