define("ember-modals-manager-internal/components/emmi-modals-container/process", ["exports", "ember-modals-manager-internal/components/emmi-modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Here `process` means function thar return Promise
   *
   * @class Process
   * @namespace Components
   * @extends Components.BaseModal
   */
  class ProcessModal extends _base.default {
    didInsertElement() {
      const process = this.options.process;
      if (process) {
        process().then(v => this.send('confirm', v)).catch(e => this.send('decline', e));
      }
    }
  }
  _exports.default = ProcessModal;
});