define("ember-bootstrap/components/base/bs-accordion", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-accordion", "ember-bootstrap/utils/cp/listen-to", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _bsAccordion, _listenTo, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
    Bootstrap-style [accordion group](http://getbootstrap.com/javascript/#collapse-example-accordion),
    with collapsible/expandable items.
  
    ### Usage
  
    Use as a block level component with any number of yielded [Components.AccordionItem](Components.AccordionItem.html)
    components as children:
  
    ```handlebars
    <BsAccordion as |Acc|>
      <Acc.item @value={{1}} @title="First item">
        <p>Lorem ipsum...</p>
        <button onclick={{action acc.change 2}}>Next</button>
      </Acc.item>
      <Acc.item @value={{2}} @title="Second item">
        <p>Lorem ipsum...</p>
      </Acc.item>
      <Acc.item @value={{3}} @title="Third item">
        <p>Lorem ipsum...</p>
      </Acc.item>
    </BsAccordion>
    ```
  
    In the example above the first accordion item utilizes the yielded `change` action to add some custom behaviour.
  
    @class Accordion
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Accordion = (_dec = (0, _component.layout)(_bsAccordion.default), _dec2 = (0, _listenTo.default)('selected'), _dec3 = Ember._action, _dec(_class = (_class2 = class Accordion extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ariaRole", _descriptor, this);
      _initializerDefineProperty(this, "selected", _descriptor2, this);
      _initializerDefineProperty(this, "itemComponent", _descriptor3, this);
      _initializerDefineProperty(this, "isSelected", _descriptor4, this);
    }
    /**
     * Action when the selected accordion item is about to be changed.
     *
     * You can return false to prevent changing the active item, and do that in your action by
     * setting the `selected` accordingly.
     *
     * @event onChange
     * @param newValue
     * @param oldValue
     * @public
     */
    onChange(newValue, oldValue) {} // eslint-disable-line no-unused-vars

    doChange(newValue) {
      let oldValue = this.get('isSelected');
      if (oldValue === newValue) {
        newValue = null;
      }
      if (this.get('onChange')(newValue, oldValue) !== false) {
        this.set('isSelected', newValue);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ariaRole", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'tablist';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "selected", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "itemComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-accordion/item';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "doChange", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "doChange"), _class2.prototype)), _class2)) || _class);
  _exports.default = Accordion;
});