define("ember-gestures/templates/components/slide-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8CT5Kgq2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"slideToggleButton\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-gestures/templates/components/slide-toggle.hbs"
  });
  _exports.default = _default;
});