define("ember-modals-manager-internal/components/emmi-modals-container/confirm", ["exports", "ember-modals-manager-internal/components/emmi-modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Confirm-modal
   *
   * @class ConfirmModal
   * @namespace Components
   * @extends Components.BaseModal
   */
  class ConfirmModal extends _base.default {}
  _exports.default = ConfirmModal;
});