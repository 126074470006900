define("ember-steps/helpers/validate-transition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.validateTransition = validateTransition;
  /** @hide */
  function validateTransition(_ref, _ref2) {
    let [transition] = _ref;
    let {
      with: validator
    } = _ref2;
    return function () {
      return new Ember.RSVP.Promise(resolve => {
        validator(resolve);
      }).then(() => {
        transition();
      });
    };
  }
  var _default = Ember.Helper.helper(validateTransition);
  _exports.default = _default;
});