define("ember-bootstrap/templates/components/bs-form/element/help-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C2rK4Jnw",
    "block": "{\"symbols\":[\"@text\"],\"statements\":[[1,[32,1]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/help-text.hbs"
  });
  _exports.default = _default;
});