define("ember-svg-jar/inlined/dashboard-employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 4a4 4 0 11-8 0 4 4 0 018 0zM9 7a3 3 0 100-6 3 3 0 000 6z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.098 2.573c2.12-.425 3.347 1.112 3.347 2.468A2.464 2.464 0 0112.977 7.5a.5.5 0 000 1 3.464 3.464 0 003.468-3.459c0-1.912-1.732-4.011-4.543-3.449a.5.5 0 00.196.98zM5.847 2.573C3.727 2.148 2.5 3.685 2.5 5.04A2.464 2.464 0 004.968 7.5a.5.5 0 110 1A3.464 3.464 0 011.5 5.041c0-1.912 1.732-4.011 4.543-3.449a.5.5 0 11-.196.98z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 18H3v-6.296A4.704 4.704 0 017.704 7h2.592A4.704 4.704 0 0115 11.704V18zm-1-6.296V17H4v-5.296A3.704 3.704 0 017.704 8h2.592A3.704 3.704 0 0114 11.704z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.76 8.5A2.24 2.24 0 0117 10.74v4.76a1.5 1.5 0 01-1.5 1.5H11v1h4.5a2.5 2.5 0 002.5-2.5v-4.76a3.24 3.24 0 00-3.24-3.24H12.5v1h2.26zM3.24 8.5A2.24 2.24 0 001 10.74v4.76A1.5 1.5 0 002.5 17H7v1H2.5A2.5 2.5 0 010 15.5v-4.76A3.24 3.24 0 013.24 7.5H5.5v1H3.24z\" fill=\"#505152\"/><path d=\"M8 8v-.5h2V8a1 1 0 01-2 0z\" fill=\"#505152\"/><path d=\"M7.555 12.78l1.057-4.228c.1-.404.675-.404.776 0l1.057 4.228a.4.4 0 01-.105.38l-1.057 1.057a.4.4 0 01-.566 0L7.66 13.16a.4.4 0 01-.105-.38z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});