define("@bagaar/ember-breadcrumbs/templates/components/breadcrumbs-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nzG9BL7B",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[2,\"\\n\"],[11,\"ul\"],[17,1],[4,[38,1],[[30,[36,0],[[32,0],[32,0,[\"registerContainer\"]]],null]],null],[4,[38,2],[[30,[36,0],[[32,0],[32,0,[\"unregisterContainer\"]]],null]],null],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\",\"will-destroy\"]}",
    "moduleName": "@bagaar/ember-breadcrumbs/templates/components/breadcrumbs-container.hbs"
  });
  _exports.default = _default;
});