define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.733 4.733h10.534v10.1a1.1 1.1 0 01-1.1 1.1H3.833a1.1 1.1 0 01-1.1-1.1v-10.1zm1.2 1.2v8.8h8.134v-8.8H3.933z\" fill=\"#1E1E1E\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.667 6.733a.6.6 0 01.6.6v6a.6.6 0 11-1.2 0v-6a.6.6 0 01.6-.6zM8 6.733a.6.6 0 01.6.6v6a.6.6 0 11-1.2 0v-6a.6.6 0 01.6-.6zM10.333 6.733a.6.6 0 01.6.6v6a.6.6 0 11-1.2 0v-6a.6.6 0 01.6-.6zM6.067 1.167a1.1 1.1 0 011.1-1.1h1.666a1.1 1.1 0 011.1 1.1v1a1.1 1.1 0 01-1.1 1.1H7.167a1.1 1.1 0 01-1.1-1.1v-1zm1.2.1v.8h1.466v-.8H7.267z\" fill=\"#1E1E1E\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.067 3.167a1.1 1.1 0 011.1-1.1h9.666a1.1 1.1 0 011.1 1.1v1.666a1.1 1.1 0 01-1.1 1.1H3.167a1.1 1.1 0 01-1.1-1.1V3.167zm1.2.1v1.466h9.466V3.267H3.267z\" fill=\"#1E1E1E\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});