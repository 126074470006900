define("ember-bootstrap/templates/components/bs-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "A3/TvaCC",
    "block": "{\"symbols\":[\"Element\",\"@title\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[1,[30,[36,4],[[35,3]],null]],[2,\"\\n\"],[6,[37,5],[[32,0,[\"inDom\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"elementComponent\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[[17,4]],[[\"@placement\",\"@fade\",\"@showHelp\",\"@title\",\"@renderInPlace\",\"@popperTarget\",\"@destinationElement\",\"@autoPlacement\",\"@viewportElement\",\"@viewportPadding\",\"@id\",\"@class\"],[[32,0,[\"placement\"]],[32,0,[\"fade\"]],[32,0,[\"showHelp\"]],[32,2],[32,0,[\"_renderInPlace\"]],[32,0,[\"triggerTargetElement\"]],[32,0,[\"destinationElement\"]],[32,0,[\"autoPlacement\"]],[32,0,[\"viewportElement\"]],[32,0,[\"viewportPadding\"]],[32,0,[\"overlayId\"]],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,5,[[30,[36,0],null,[[\"close\"],[[32,0,[\"close\"]]]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"component\",\"let\",\"_parentFinder\",\"unbound\",\"if\"]}",
    "moduleName": "ember-bootstrap/templates/components/bs-popover.hbs"
  });
  _exports.default = _default;
});