define("ember-power-calendar/templates/components/power-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yd9aT1HN",
    "block": "{\"symbols\":[\"calendar\",\"Tag\",\"&default\",\"&attrs\"],\"statements\":[[6,[37,8],[[30,[36,7],[[32,0,[\"publicAPI\"]],[30,[36,6],null,[[\"Nav\",\"Days\"],[[30,[36,3],[[32,0,[\"navComponent\"]]],[[\"calendar\"],[[30,[36,5],[[32,0,[\"publicAPI\"]]],null]]]],[30,[36,3],[[32,0,[\"daysComponent\"]]],[[\"calendar\"],[[30,[36,5],[[32,0,[\"publicAPI\"]]],null]]]]]]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,2],[[30,[36,1],[[32,0,[\"tagWithDefault\"]]],null]],null]],[[\"tagName\"],[[32,0,[\"tagWithDefault\"]]]]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,2],[[24,0,\"ember-power-calendar\"],[17,4],[16,1,[32,1,[\"uniqueId\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,3,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,[32,1,[\"Nav\"]],[],[[],[]],null],[2,\"\\n        \"],[8,[32,1,[\"Days\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-element\",\"ensure-safe-component\",\"component\",\"let\",\"readonly\",\"hash\",\"assign\",\"with\"]}",
    "moduleName": "ember-power-calendar/templates/components/power-calendar.hbs"
  });
  _exports.default = _default;
});