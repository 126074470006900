define("ember-modals-manager-internal/components/emmi-modals-container/prompt", ["exports", "ember-modals-manager-internal/components/emmi-modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   * Prompt-modal
   *
   * @class PromptModal
   * @namespace Components
   * @extends Components.BaseModal
   */
  let PromptModal = (_dec = Ember.computed('promptValue', 'options.disallowEmpty'), _dec2 = Ember._action, _dec3 = Ember._action, (_class = class PromptModal extends _base.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "promptValue", '');
      _defineProperty(this, "inputType", 'text');
    }
    get confirmDisabled() {
      return this.options.disallowEmpty ? Ember.isEmpty(this.promptValue) : false;
    }

    /**
     * @event confirm
     */
    confirm() {
      super.confirm(this.promptValue);
    }

    /**
     * @event updatePromptValue
     * @param {*} val
     */
    updatePromptValue(val) {
      Ember.set(this, 'promptValue', val);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "confirmDisabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "confirmDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirm", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "confirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePromptValue", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updatePromptValue"), _class.prototype)), _class));
  _exports.default = PromptModal;
});