define("ember-bootstrap/components/base/bs-dropdown/toggle", ["exports", "@ember-decorators/component", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   Anchor element that triggers the parent dropdown to open.
   Use [Components.DropdownButton](Components.DropdownButton.html) if you want a button instead of an anchor tag.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownToggle
   @namespace Components
   @extends Ember.Component
   @publicø
   */
  let DropdownToggle = (_dec = (0, _component.classNames)('dropdown-toggle'), _dec2 = (0, _component.tagName)('a'), _dec3 = (0, _component.attributeBindings)('href'), _dec4 = (0, _component.attributeBindings)('aria-expanded'), _dec5 = Ember.computed('isOpen'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class DropdownToggle extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "ariaRole", 'button');
      _initializerDefineProperty(this, "inNav", _descriptor, this);
    }
    get 'aria-expanded'() {
      return this.isOpen ? 'true' : 'false';
    }

    /**
     * Computed property to generate a `href="#"` attribute when `tagName` is "a".
     *
     * @property href
     * @type string
     * @readonly
     * @private
     */
    get href() {
      return this.get('tagName').toUpperCase() === 'A' ? '#' : undefined;
    }

    /**
     * When clicking the toggle this action is called.
     *
     * @event onClick
     * @param {*} value
     * @public
     */
    onClick() {}
    click(e) {
      e.preventDefault();
      this.get('onClick')();
    }
    keyDown(e) {
      this.get('onKeyDown')(e);
    }
    didReceiveAttrs() {
      // super.didReceiveAttrs(...arguments);
      let dropdown = this.get('dropdown');
      if (dropdown) {
        Ember.run.schedule('actions', this, function () {
          if (!this.get('isDestroyed')) {
            dropdown.set('toggle', this);
          }
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "inNav", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, 'aria-expanded', [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, 'aria-expanded'), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class);
  _exports.default = DropdownToggle;
});