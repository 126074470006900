define("ember-bootstrap/components/bs-form/element/errors", ["exports", "ember-bootstrap/components/base/bs-form/element/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class FormElementErrors extends _errors.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "feedbackClass", 'invalid-feedback d-block');
    }
  }
  _exports.default = FormElementErrors;
});