define("ember-bootstrap/templates/components/bs-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xbx4X7Ay",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"bar\"],[[30,[36,0],[[32,0,[\"progressBarComponent\"]]],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "ember-bootstrap/templates/components/bs-progress.hbs"
  });
  _exports.default = _default;
});