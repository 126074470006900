define("ember-bootstrap/templates/components/bs-form/element/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1Zd4Elff",
    "block": "{\"symbols\":[\"&default\",\"@label\"],\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[32,2]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/label.hbs"
  });
  _exports.default = _default;
});