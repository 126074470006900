define("ember-table/components/ember-th/resize-handle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
      {{#if this.isResizable}}
      <div data-test-resize-handle class="et-header-resize-area">
      </div>
    {{/if}}
  */
  {
    "id": "n/HJFJx5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"isResizable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,\"data-test-resize-handle\",\"\"],[14,0,\"et-header-resize-area\"],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ember-table/components/ember-th/resize-handle/template.hbs"
  });
  _exports.default = _default;
});