define("@bagaar/ember-breadcrumbs/services/breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    /**
     * State
     */

    containers: null,
    /**
     * Hooks
     */

    init() {
      this._super(...arguments);
      this.containers = [];
    },
    /**
     * Methods
     */

    registerContainer(containerData) {
      (true && !(containerData.element) && Ember.assert('An `element` is required to register a breadcrumb container', containerData.element));
      (true && !(!this._isContainerRegistered(containerData)) && Ember.assert('This container was already registered before', !this._isContainerRegistered(containerData)));
      this.set('containers', [...this.containers, containerData]);
    },
    unregisterContainer(_ref) {
      let {
        element
      } = _ref;
      this.set('containers', this.containers.filter(registeredContainer => {
        return element !== registeredContainer.element;
      }));
    },
    _isContainerRegistered(_ref2) {
      let {
        element
      } = _ref2;
      return this.containers.some(registeredContainer => {
        return element === registeredContainer.element;
      });
    }
  });
  _exports.default = _default;
});