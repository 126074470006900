define("ember-table/-private/sticky/table-sticky-polyfill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setupTableStickyPolyfill = setupTableStickyPolyfill;
  _exports.teardownTableStickyPolyfill = teardownTableStickyPolyfill;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  /* global ResizeSensor */
  /* eslint-disable ember/no-observers */

  const TABLE_POLYFILL_MAP = new WeakMap();
  class TableStickyPolyfill {
    constructor(element) {
      _defineProperty(this, "setupRowMutationObservers", () => {
        let rows = Array.from(this.element.children);
        this.rowMutationObservers = rows.map(row => {
          let observer = new MutationObserver(this.repositionStickyElements);
          observer.observe(row, {
            childList: true
          });
          return observer;
        });
      });
      _defineProperty(this, "teardownRowMutationObservers", () => {
        this.rowMutationObservers.forEach(observer => observer.disconnect());
      });
      _defineProperty(this, "setupResizeSensors", () => {
        let rows = Array.from(this.element.children);
        let firstCells = rows.map(r => r.firstElementChild);
        this.resizeSensors = firstCells.map(cell => {
          let sensor = new ResizeSensor(cell, this.repositionStickyElements);
          return [cell, sensor];
        });
      });
      _defineProperty(this, "teardownResizeSensors", () => {
        this.resizeSensors.forEach(_ref => {
          let [cell, sensor] = _ref;
          return sensor.detach(cell);
        });
      });
      _defineProperty(this, "repositionStickyElements", () => {
        let table = this.element.parentNode;
        let scale = table.offsetHeight / table.getBoundingClientRect().height;
        let containerHeight = table.parentNode.offsetHeight;

        // exclude ResizeSensor divs
        let rows = Array.from(this.element.querySelectorAll('tr'));
        let offset = 0;
        let heights = rows.map(r => r.getBoundingClientRect().height * scale);
        let totalHeight = heights.reduce((sum, h) => sum += h, 0);
        let maxHeight = containerHeight * this.maxStickyProportion;
        if (totalHeight > maxHeight) {
          offset = maxHeight - totalHeight;
        }
        for (let i = 0; i < rows.length; i++) {
          // Work top-down (index order) for 'top', bottom-up (reverse index
          // order) for 'bottom' rows
          let index = this.side === 'top' ? i : rows.length - 1 - i;
          let row = rows[index];
          let height = heights[index];
          for (let child of row.children) {
            child.style.position = '-webkit-sticky';
            child.style.position = 'sticky';
            child.style[this.side] = `${offset}px`;
          }
          offset += height;
        }
      });
      this.element = element;
      this.maxStickyProportion = 0.5;
      this.element.style.position = 'static';
      this.side = element.tagName === 'THEAD' ? 'top' : 'bottom';
      this.setupRaf = requestAnimationFrame(this.repositionStickyElements);
      this.setupResizeSensors();
      this.setupRowMutationObservers();
      this.mutationObserver = new MutationObserver(() => {
        this.teardownResizeSensors();
        this.teardownRowMutationObservers();
        this.setupResizeSensors();
        this.setupRowMutationObservers();
        this.repositionStickyElements();
      });
      this.mutationObserver.observe(this.element, {
        childList: true
      });
    }
    destroy() {
      this.element.style.position = 'sticky';
      cancelAnimationFrame(this.setupRaf);
      this.teardownResizeSensors();
      this.teardownRowMutationObservers();
      this.mutationObserver.disconnect();
    }
  }
  function setupTableStickyPolyfill(element) {
    TABLE_POLYFILL_MAP.set(element, new TableStickyPolyfill(element));
  }
  function teardownTableStickyPolyfill(element) {
    TABLE_POLYFILL_MAP.get(element).destroy();
    TABLE_POLYFILL_MAP.delete(element);
  }
});