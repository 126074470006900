define("ember-bootstrap/templates/components/bs-button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DjGDJ0AT",
    "block": "{\"symbols\":[\"@value\",\"@type\",\"&default\"],\"statements\":[[18,3,[[30,[36,1],null,[[\"button\"],[[30,[36,0],[[32,0,[\"buttonComponent\"]]],[[\"buttonGroupType\",\"groupValue\",\"onClick\"],[[32,2],[32,1],[32,0,[\"buttonPressed\"]]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "ember-bootstrap/templates/components/bs-button-group.hbs"
  });
  _exports.default = _default;
});