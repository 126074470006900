define("ember-svg-jar/inlined/dashboard-clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 4a4 4 0 11-8 0 4 4 0 018 0zM9 7a3 3 0 100-6 3 3 0 000 6z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.597 3.573C14.76 3.146 16 4.693 16 6.04c0 1.35-1.116 2.459-2.51 2.459a.5.5 0 100 1C15.42 9.5 17 7.96 17 6.041c0-1.92-1.76-4.009-4.597-3.45a.5.5 0 10.194.982zM5.403 3.573C3.24 3.146 2 4.693 2 6.04 2 7.391 3.115 8.5 4.51 8.5a.5.5 0 010 1C2.58 9.5 1 7.96 1 6.041c0-1.92 1.76-4.009 4.597-3.45a.5.5 0 01-.194.982z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14 18H4v-7.037A3.963 3.963 0 017.963 7h2.074A3.963 3.963 0 0114 10.963V18zm-1-7.037V17H5v-6.037A2.963 2.963 0 017.963 8h2.074A2.963 2.963 0 0113 10.963z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.26 9.5A2.74 2.74 0 0117 12.24v3.26a1.5 1.5 0 01-1.5 1.5h-5v1h5a2.5 2.5 0 002.5-2.5v-3.26a3.74 3.74 0 00-3.74-3.74H13v1h1.26zM3.74 9.5A2.74 2.74 0 001 12.24v3.26A1.5 1.5 0 002.5 17h2v1h-2A2.5 2.5 0 010 15.5v-3.26A3.74 3.74 0 013.74 8.5H5v1H3.74z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});