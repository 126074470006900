define("ember-modals-manager-internal/components/emmi-modals-container/progress", ["exports", "ember-modals-manager-internal/components/emmi-modals-container/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * Here `promises` means functions that return Promise
   *
   * @class ProgressModal
   * @namespace Components
   * @extends Components.BaseModal
   */
  let ProgressModal = (_dec = Ember.computed.readOnly('options.settled'), _dec2 = Ember.computed.readOnly('options.promises'), _dec3 = Ember.computed('done', 'promisesCount'), _dec4 = Ember._action, (_class = class ProgressModal extends _base.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "done", 0);
      _defineProperty(this, "promisesCount", 0);
      _defineProperty(this, "canceled", false);
      _initializerDefineProperty(this, "settled", _descriptor, this);
      _defineProperty(this, "errors", Ember.A([]));
      _defineProperty(this, "results", Ember.A([]));
      _initializerDefineProperty(this, "promises", _descriptor2, this);
    }
    get progress() {
      if (!this.promisesCount) {
        return 100;
      }
      return this.done / this.promisesCount * 100;
    }
    cancel() {
      Ember.set(this, 'canceled', true);
    }
    didInsertElement() {
      Ember.set(this, 'promisesCount', this.promises.length);
      const promise = this.promises.shift();
      if (promise) {
        void this.next(promise);
      }
    }
    next(promiseFactory) {
      if (this.canceled) {
        return this._complete();
      }
      return promiseFactory().then(result => {
        this._next(result);
        return result;
      }).catch(error => {
        if (this.settled) {
          this.errors.pushObject(error);
          this._next();
        } else {
          this.send('decline', [this.results, error]);
        }
        return error;
      });
    }
    _next(result) {
      Ember.run(() => {
        if (arguments.length === 1) {
          this.results.pushObject(result);
        }
        this.incrementProperty('done');
      });
      const promise = this.promises.shift();
      if (promise) {
        void this.next(promise);
      } else {
        // wait for last "tick" animation
        this._complete();
      }
    }
    _complete() {
      Ember.run.later(() => this.send('confirm', this.settled ? [this.results, this.errors] : this.results), 500);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settled", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "promises", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "progress", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "progress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = ProgressModal;
});