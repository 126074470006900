define("ember-bootstrap/components/base/bs-form/group", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-form/group"], function (_exports, _component, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
    This component renders a `<div class="form-group">` element, with support for validation states and feedback icons (only for BS3).
    You can use it as a block level component. The following shows Bootstrap 3 usage for the internal markup.
  
    ```hbs
    <BsForm as |form|>
      <form.group @validation={{this.firstNameValidation}}>
        <label class="control-label">First name</label>
        <input value={{this.firstname}} class="form-control" oninput={{action (mut this.firstname) value="target.value"}} type="text">
      </form.group>
    </bs-form>
    ```
  
    If the `validation` property is set to some state (usually Bootstrap's predefined states "success",
    "warning" or "error"), the appropriate styles will be added, together with a feedback icon.
    See http://getbootstrap.com/css/#forms-control-validation
  
    @class FormGroup
    @namespace Components
    @extends Ember.Component
    @public
  */
  let FormGroup = (_dec = (0, _component.layout)(_group.default), _dec2 = Ember.computed.notEmpty('validation').readOnly(), _dec(_class = (_class2 = class FormGroup extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "hasValidation", _descriptor, this);
    }
    /**
     * Set to a validation state to render the form-group with a validation style (only for BS3).
     * See http://getbootstrap.com/css/#forms-control-validation
     *
     * The default states of "success", "warning" and "error" are supported by Bootstrap out-of-the-box.
     * But you can use custom states as well. This will set a has-<state> class, and (if `useIcons`is true)
     * a feedback whose class is taken from the <state>Icon property
     *
     * @property validation
     * @type string
     * @public
     */
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "hasValidation", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = FormGroup;
});