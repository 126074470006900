define("ember-svg-jar/inlined/dashboard-brokers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 4a4 4 0 11-8 0 4 4 0 018 0zM9 7a3 3 0 100-6 3 3 0 000 6z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.444 12.72v2.058A3.222 3.222 0 0113.222 18h-8A3.222 3.222 0 012 14.778V12.72a5.609 5.609 0 015.609-5.609h3.226a5.609 5.609 0 015.61 5.61zm-1 0v2.058A2.222 2.222 0 0113.222 17h-8A2.222 2.222 0 013 14.778V12.72a4.609 4.609 0 014.609-4.609h3.226a4.609 4.609 0 014.61 4.61z\" fill=\"#505152\"/><path d=\"M8.156 8.644v-.533h2.133v.533a1.067 1.067 0 01-2.133 0z\" fill=\"#505152\"/><path d=\"M7.688 13.359l1.068-4.274c.122-.485.81-.485.932 0l1.068 4.274a.48.48 0 01-.126.455l-1.068 1.069a.48.48 0 01-.68 0l-1.068-1.069a.48.48 0 01-.126-.455z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});