define("ember-bootstrap/templates/components/bs-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "K58GKHj/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"item\",\"link-to\",\"linkTo\",\"dropdown\"],[[30,[36,0],[[32,0,[\"itemComponent\"]]],null],[30,[36,0],[[32,0,[\"linkToComponent\"]]],null],[30,[36,0],[[32,0,[\"linkToComponent\"]]],null],[30,[36,0],[[32,0,[\"dropdownComponent\"]]],[[\"inNav\",\"tagName\"],[true,\"li\"]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "ember-bootstrap/templates/components/bs-nav.hbs"
  });
  _exports.default = _default;
});