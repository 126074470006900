define("@bagaar/ember-breadcrumbs/components/breadcrumbs-container", ["exports", "@bagaar/ember-breadcrumbs/templates/components/breadcrumbs-container"], function (_exports, _breadcrumbsContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /**
     * Services
     */

    breadcrumbsService: Ember.inject.service('breadcrumbs'),
    /**
     * Arguments
     */

    itemClass: null,
    linkClass: null,
    /**
     * State
     */

    layout: _breadcrumbsContainer.default,
    tagName: '',
    containerData: null,
    /**
     * Actions
     */

    registerContainer(element) {
      this.containerData = {
        element,
        itemClass: this.itemClass,
        linkClass: this.linkClass
      };
      this.breadcrumbsService.registerContainer(this.containerData);
    },
    unregisterContainer() {
      this.breadcrumbsService.unregisterContainer(this.containerData);
    }
  });
  _exports.default = _default;
});