define("ember-data-autocomplete-js/components/autocomplete/component", ["exports", "autocomplete.js-cybertooth.io/dist/autocomplete"], function (_exports, _autocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * This `{{autocomplete}}` component is a textbox powered by `autocomplete.js`
   * and Ember Data's `store` in fetching type ahead completion results that your
   * user can choose.
   *
   * @extends {TextField}
   */
  let Autocomplete = (_dec = Ember.inject.service, (_class = class Autocomplete extends Ember.TextField {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _defineProperty(this, "additionalFilters", {});
      _defineProperty(this, "debounce", 250);
      _defineProperty(this, "displayKey", 'id');
      _defineProperty(this, "filter", '');
      _defineProperty(this, "globalOptions", {
        appendTo: null,
        ariaLabel: null,
        autoselect: false,
        autoselectOnBlur: false,
        autoWidth: true,
        clearOnSelected: false,
        cssClasses: {
          cursor: 'cursor',
          dataset: 'dataset',
          dropdownMenu: 'dropdown-menu',
          empty: 'empty',
          hint: 'hint',
          input: 'input',
          noPrefix: false,
          prefix: 'aa',
          root: 'algolia-autocomplete',
          suggestion: 'suggestion',
          suggestions: 'suggestions'
        },
        debug: false,
        dropdownMenuContainer: null,
        hint: true,
        keyboardShortcuts: [],
        minLength: 1,
        openOnFocus: false,
        tabAutocomplete: true,
        templates: {
          dropdownMenu: null,
          header: null,
          footer: null,
          empty: null
        }
      });
      _defineProperty(this, "include", '');
      _defineProperty(this, "modelName", '');
      _defineProperty(this, "page", {
        number: 1,
        size: 10
      });
      _defineProperty(this, "sort", '');
      _defineProperty(this, "suggestion", undefined);
      _defineProperty(this, "_autocompleteInstance", null);
    }
    /** Actions
     ------------------------------------------------------------------------------------------------------------------ */

    /**
     * The html `#id` selector of this component used to instantiate the `Autocomplete` instance.
     *
     * @private
     * @return {string}
     */
    get _selector() {
      return `#${this.elementId}`;
    }

    /**
     * Triggered when the query is autocompleted. Autocompleted means the query was changed to the hint.
     * The event handler will be invoked with 3 arguments: the jQuery event object, the suggestion
     * object, and the name of the dataset the suggestion belongs to.
     *
     * @param {Event} event - the event object
     * @param {Object} chosenItem - the Ember Data instance that was selected
     * @param {string} dataSetNameOrNumber - the name of the autocomplete.js dataset
     * @emits {autocompleted}
     * @see https://github.com/algolia/autocomplete.js#events
     * @type {Action}
     */
    autocompleted( /*event, chosenItem, dataSetNameOrNumber*/
    ) {
      // override accordingly
    }

    /**
     * Triggered when the dropdown menu of the autocomplete is closed.
     *
     * @emits {closed}
     * @see https://github.com/algolia/autocomplete.js#events
     * @type {Action}
     */
    closed() {
      // override accordingly
    }

    /**
     * Triggered when the dropdown menu cursor is moved to a different suggestion. The event
     * handler will be invoked with 3 arguments: the jQuery event object, the suggestion object, and
     * the name of the dataset the suggestion belongs to.
     *
     * @param {Event} event - the event object
     * @param {Object} chosenItem - the Ember Data instance that was selected
     * @param {string} dataSetNameOrNumber - the name of the autocomplete.js dataset
     * @emits {cursorChanged}
     * @see https://github.com/algolia/autocomplete.js#events
     * @type {Action}
     */
    cursorChanged( /*event, chosenItem, dataSetNameOrNumber*/
    ) {
      // override accordingly
    }

    /**
     * Triggered when the cursor leaves the selections or its current index is lower than 0.
     *
     * @emits {cursorRemoved}
     * @see https://github.com/algolia/autocomplete.js#events
     * @type {Action}
     */
    cursorRemoved() {
      // override accordingly
    }

    /**
     * Triggered when all datasets are empty.
     *
     * @param {Event} event - the event object
     * @emits {empty}
     * @see https://github.com/algolia/autocomplete.js#events
     * @type {Action}
     */
    empty( /*event*/
    ) {
      // override accordingly
    }

    /**
     * Triggered when the dropdown menu of the autocomplete is opened.
     *
     * @param {Event} event - the event object
     * @emits {opened}
     * @see https://github.com/algolia/autocomplete.js#events
     * @type {Action}
     */
    opened( /*event*/
    ) {
      // override accordingly
    }

    /**
     * Triggered when appendTo is used and the wrapper is resized/repositionned.
     *
     * @emits {redrawn}
     * @see https://github.com/algolia/autocomplete.js#events
     * @type {Action}
     */
    redrawn() {
      // override accordingly
    }

    /**
     * Triggered when a suggestion from the dropdown menu is selected. The event handler will be invoked
     * with the following arguments: the jQuery event object, the suggestion object, the name of the
     * dataset the suggestion belongs to and a context object. The context contains a .selectionMethod
     * key that can be either click, enterKey, tabKey or blur, depending how the suggestion was selected.
     *
     * @param {Event} event - the event object
     * @param {Object} chosenItem - the Ember Data instance that was selected
     * @param {string} dataSetNameOrNumber - the name of the autocomplete.js dataset
     * @param {Object} context -
     * @param {string} context.selectionMethod - the string describing how the selection
     * was made (`click`, `enterKey`, etc.)
     * @emits {selected} when a suggestion is selected
     * @see https://github.com/algolia/autocomplete.js#events
     * @type {Action}
     */
    selected( /*event, chosenItem, dataSetNameOrNumber, context*/
    ) {
      // override accordingly
    }

    /**
     * Triggered when the dropdown menu of the autocomplete is shown (opened and non-empty).
     *
     * @param {Event} event - the event object
     * @emits {shown} when the dropdown menu of the autocomplete is shown
     * @see https://github.com/algolia/autocomplete.js#events
     * @type {Action}
     */
    shown( /*event*/
    ) {
      // override accordingly
    }

    /**
     * Triggered when a dataset is rendered.
     *
     * @param {Event} event - the event object
     * @emits {updated} when a dataset is rendered
     * @see https://github.com/algolia/autocomplete.js#events
     * @type {Action}
     */
    updated( /*event*/
    ) {
      // override accordingly
    }

    /** Methods
     ------------------------------------------------------------------------------------------------------------------ */

    didInsertElement() {
      super.didInsertElement(...arguments);
      this._fixAutofocus();
      this._initializeAutocomplete();
    }

    /** Private
     ------------------------------------------------------------------------------------------------------------------ */

    /**
     * Destroy the #_autocompleteInstance instance that was created during #didInsertElement.
     */
    willDestroy() {
      this._autocompleteInstance.autocomplete.destroy();
      super.willDestroy(...arguments);
    }

    /**
     * Fixes Ember's weird bug where autofocus can't be re-triggered on subsequent transitions.
     *
     * @private
     */
    _fixAutofocus() {
      if (this.element.getAttributeNames().includes('autofocus')) {
        Ember.run.later(1, () => {
          this.element.focus();
        });
      }
    }

    /**
     * Responsible for locating this component's `<input>` element and passing it
     * to Algolia's `autocomplete.js` library for initialisation.
     *
     * @private
     */
    _initializeAutocomplete() {
      const self = this;
      this._autocompleteInstance = (0, _autocomplete.default)(this._selector, this.globalOptions, [{
        debounce: self.debounce,
        displayKey: this.displayKey,
        name: `dataset-id-${this.elementId}`,
        source: function (query, callback) {
          self.store.loadRecords(self.modelName, self._queryOptions(query)).then(response => {
            const noWay = [];
            response.forEach(modelInstance => noWay.push(modelInstance));
            callback(noWay);
            return response;
          });
        },
        templates: {
          suggestion: this.suggestion ? this.suggestion : null
        }
      }]).on('autocomplete:autocompleted', this.autocompleted).on('autocomplete:closed', this.closed).on('autocomplete:cursorchanged', this.cursorChanged).on('autocomplete:cursorremoved', this.cursorRemoved).on('autocomplete:empty', this.empty).on('autocomplete:opened', this.opened).on('autocomplete:redrawn', this.redrawn).on('autocomplete:selected', this.selected).on('autocomplete:shown', this.shown).on('autocomplete:updated', this.updated);
    }

    /**
     * @param {String} filterValue - the textbox value
     * @private
     * @todo unit tests of this
     */
    _queryOptions(filterValue) {
      const queryOptions = {
        filter: {}
      };
      queryOptions.filter[this.filter] = filterValue;
      if (Ember.isPresent(this.additionalFilters)) {
        Object.assign(queryOptions.filter, this.additionalFilters);
      }
      if (Ember.isPresent(this.include)) {
        queryOptions.include = this.include;
      }
      if (Ember.isPresent(this.page)) {
        queryOptions.page = this.page;
      }
      if (Ember.isPresent(this.sort)) {
        queryOptions.sort = this.sort;
      }
      return queryOptions;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Autocomplete;
});