define("ember-bootstrap-modals-manager/components/modals-container", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.modalIsOpened}}
    {{component
      this.componentName
      modalIsOpened=this.modalIsOpened
      options=this.options
      onConfirm=(fn this.confirm)
      onDecline=(fn this.decline)
    }}
  {{/if}}
  {{yield}}
  */
  {
    "id": "SyZH9jpI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[32,0,[\"modalIsOpened\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[32,0,[\"componentName\"]]],[[\"modalIsOpened\",\"options\",\"onConfirm\",\"onDecline\"],[[32,0,[\"modalIsOpened\"]],[32,0,[\"options\"]],[30,[36,0],[[32,0,[\"confirm\"]]],null],[30,[36,0],[[32,0,[\"decline\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"fn\",\"component\",\"if\"]}",
    "moduleName": "ember-bootstrap-modals-manager/components/modals-container.hbs"
  });
  let ModalsContainer = (_dec = Ember.inject.service, _dec2 = Ember.computed.readOnly('modalsManager.options'), _dec3 = Ember.computed.readOnly('modalsManager.modalIsOpened'), _dec4 = Ember.computed.readOnly('modalsManager.componentName'), _dec5 = Ember._action, _dec6 = Ember._action, (_class = class ModalsContainer extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modalsManager", _descriptor, this);
      _initializerDefineProperty(this, "options", _descriptor2, this);
      _initializerDefineProperty(this, "modalIsOpened", _descriptor3, this);
      _initializerDefineProperty(this, "componentName", _descriptor4, this);
    }
    confirm(v) {
      this.modalsManager.onConfirmClick(v);
    }
    decline(v) {
      this.modalsManager.onDeclineClick(v);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modalIsOpened", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "componentName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "confirm", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "confirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "decline", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "decline"), _class.prototype)), _class));
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModalsContainer);
  _exports.default = _default;
});