define("ember-drag-sort/templates/components/drag-sort-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#each this.items as |item index|}}
    <DragSortItem
      @additionalArgs                 = {{this.additionalArgs}}
      @item                           = {{item}}
      @index                          = {{index}}
      @items                          = {{this.items}}
      @group                          = {{this.group}}
      @handle                         = {{this.handle}}
      @tagName                        = {{this.childTagName}}
      @isHorizontal                   = {{this.isHorizontal}}
      @isRtl                          = {{this.isRtl}}
      @draggingEnabled                = {{this.draggingEnabled}}
      @sourceOnly                     = {{this.sourceOnly}}
  
      @dragEndAction                  = {{this.dragEndAction}}
      @dragStartAction                = {{this.dragStartAction}}
      @determineForeignPositionAction = {{this.determineForeignPositionAction}}
  
      class = {{this.childClass}}
    >
      {{yield item index}}
    </DragSortItem>
  {{/each}}
  
  */
  {
    "id": "pTW13Y8L",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"items\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"drag-sort-item\",[[16,0,[32,0,[\"childClass\"]]]],[[\"@additionalArgs\",\"@item\",\"@index\",\"@items\",\"@group\",\"@handle\",\"@tagName\",\"@isHorizontal\",\"@isRtl\",\"@draggingEnabled\",\"@sourceOnly\",\"@dragEndAction\",\"@dragStartAction\",\"@determineForeignPositionAction\"],[[32,0,[\"additionalArgs\"]],[32,1],[32,2],[32,0,[\"items\"]],[32,0,[\"group\"]],[32,0,[\"handle\"]],[32,0,[\"childTagName\"]],[32,0,[\"isHorizontal\"]],[32,0,[\"isRtl\"]],[32,0,[\"draggingEnabled\"]],[32,0,[\"sourceOnly\"]],[32,0,[\"dragEndAction\"]],[32,0,[\"dragStartAction\"]],[32,0,[\"determineForeignPositionAction\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,3,[[32,1],[32,2]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "ember-drag-sort/templates/components/drag-sort-list.hbs"
  });
  _exports.default = _default;
});