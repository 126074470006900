define("ember-set-helper/helpers/set", ["exports", "ember-set-helper/helpers/-set-placeholder"], function (_exports, _setPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function set(positional) {
    let [target, key, valueOrPlaceholder] = positional;
    (true && !(positional.length > 2) && Ember.assert('you must pass a path and a value to the `(set)` helper. The value can be a defered value, using placeholder syntax, e.g. `(set this.value _)`', positional.length > 2));
    (true && !(positional.length === 3) && Ember.assert('you cannot pass more than a path and a value to set', positional.length === 3));
    (true && !(Boolean(target) && typeof key === 'string' || typeof key === 'symbol') && Ember.assert('you must pass a path to {{set}}', Boolean(target) && typeof key === 'string' || typeof key === 'symbol'));
    if (valueOrPlaceholder instanceof _setPlaceholder.Placeholder) {
      return _value => {
        let path = valueOrPlaceholder.path;
        let value = path === null ? _value : Ember.get(_value, path);
        return Ember.set(target, key, value);
      };
    } else {
      return () => Ember.set(target, key, valueOrPlaceholder);
    }
  }
  var _default = Ember.Helper.helper(set);
  _exports.default = _default;
});