define("ember-bootstrap/templates/components/bs-carousel/slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "k+Itq/9j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-bootstrap/templates/components/bs-carousel/slide.hbs"
  });
  _exports.default = _default;
});