define("ember-bootstrap/components/base/bs-button", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-bootstrap/templates/components/bs-button", "ember-bootstrap/utils/cp/size-class", "ember-bootstrap/utils/cp/type-class", "ember-bootstrap/utils/cp/overrideable", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _object, _bsButton, _sizeClass, _typeClass, _overrideable, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
    Implements a HTML button element, with support for all [Bootstrap button CSS styles](http://getbootstrap.com/css/#buttons)
    as well as advanced functionality such as button states.
  
    ### Basic Usage
  
    ```hbs
    <BsButton @type="primary" @icon="glyphicon glyphicon-download">
      Downloads
    </BsButton>
    ```
  
    ### Actions
  
    Use the `onClick` property of the component to send an action to your controller. It will receive the button's value
    (see the `value` property) as an argument.
  
    ```hbs
    <BsButton @type="primary" @icon="glyphicon glyphicon-download" @onClick=(action "download")>
      Downloads
    </BsButton>
    ```
  
    ### Promise support for automatic state change
  
    When returning a Promise for any asynchronous operation from the `onClick` closure action the button will
    manage an internal state ("default" > "pending" > "fulfilled"/"rejected") automatically, changing its label
    according to the state of the promise:
  
    ```hbs
    <BsButton
      (at)type="primary"
      (at)icon="glyphicon glyphicon-download"
      (at)defaultText="Download"
      (at)pendingText="Loading..."
      (at)fulfilledText="Completed!"
      (at)rejectedText="Oups!?"
      (at)onClick={{action "download"}}
    />
    ```
  
    ```js
    // controller.js
    export default Ember.Controller.extend({
      actions: {
        download(value) {
          return new Ember.RSVP.Promise(...);
        }
      }
    });
    ```
  
    For further customization `isPending`, `isFulfilled`, `isRejected` and `isSettled` properties are yielded:
  
    ```hbs
    <BsButton @onClick=(action "download") as |button|>
      Download
      {{#if button.isPending}}
        <span class="loading-spinner"></span>
      {{/if}}
    </BsButton>
    ```
  
    You can `reset` the state represented by these properties and used for button's text by setting `reset` property to
    `true`.
  
    @class Button
    @namespace Components
    @extends Ember.Component
    @public
  */
  let Button = (_dec = (0, _component.layout)(_bsButton.default), _dec2 = (0, _component.tagName)('button'), _dec3 = (0, _component.classNames)('btn'), _dec4 = (0, _component.classNameBindings)('active', 'block:btn-block', 'sizeClass', 'typeClass'), _dec5 = (0, _component.attributeBindings)('__disabled:disabled', 'buttonType:type', 'title'), _dec6 = Ember.computed('disabled', '_disabled', 'isPending', 'preventConcurrency'), _dec7 = (0, _overrideable.default)('active', function () {
    if (this.get('active')) {
      return this.get('iconActive');
    } else {
      return this.get('iconInactive');
    }
  }), _dec8 = Ember.computed.equal('state', 'pending'), _dec9 = Ember.computed.equal('state', 'fulfilled'), _dec10 = Ember.computed.equal('state', 'rejected'), _dec11 = Ember.computed.or('isFulfilled', 'isRejected'), _dec12 = (0, _sizeClass.default)('btn', 'size'), _dec13 = (0, _typeClass.default)('btn', 'type'), _dec14 = (0, _object.observes)('reset'), _dec15 = Ember.computed('state', 'defaultText', 'pendingText', 'fulfilledText', 'rejectedText'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = _dec5(_class = (_class2 = class Button extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "defaultText", _descriptor, this);
      _initializerDefineProperty(this, "pendingText", _descriptor2, this);
      _initializerDefineProperty(this, "fulfilledText", _descriptor3, this);
      _initializerDefineProperty(this, "rejectedText", _descriptor4, this);
      _initializerDefineProperty(this, "disabled", _descriptor5, this);
      _initializerDefineProperty(this, "_disabled", _descriptor6, this);
      _initializerDefineProperty(this, "buttonType", _descriptor7, this);
      _initializerDefineProperty(this, "active", _descriptor8, this);
      _initializerDefineProperty(this, "block", _descriptor9, this);
      _initializerDefineProperty(this, "bubble", _descriptor10, this);
      _initializerDefineProperty(this, "iconActive", _descriptor11, this);
      _initializerDefineProperty(this, "iconInactive", _descriptor12, this);
      _initializerDefineProperty(this, "icon", _descriptor13, this);
      _initializerDefineProperty(this, "value", _descriptor14, this);
      _initializerDefineProperty(this, "preventConcurrency", _descriptor15, this);
      _initializerDefineProperty(this, "state", _descriptor16, this);
      _initializerDefineProperty(this, "isPending", _descriptor17, this);
      _initializerDefineProperty(this, "isFulfilled", _descriptor18, this);
      _initializerDefineProperty(this, "isRejected", _descriptor19, this);
      _initializerDefineProperty(this, "isSettled", _descriptor20, this);
      _initializerDefineProperty(this, "reset", _descriptor21, this);
      _initializerDefineProperty(this, "size", _descriptor22, this);
      _initializerDefineProperty(this, "sizeClass", _descriptor23, this);
      _initializerDefineProperty(this, "typeClass", _descriptor24, this);
      _initializerDefineProperty(this, "title", _descriptor25, this);
      _initializerDefineProperty(this, "onClick", _descriptor26, this);
    }
    get __disabled() {
      if (this.get('disabled') !== null) {
        return this.get('disabled');
      }
      if (this.get('_disabled') !== null) {
        return this.get('_disabled');
      }
      return this.get('isPending') && this.get('preventConcurrency');
    }

    /**
     * Set the type of the button, either 'button' or 'submit'
     *
     * @property buttonType
     * @type String
     * @default 'button'
     * @deprecated
     * @public
     */

    /**
     * This will reset the state property to 'default', and with that the button's label to defaultText
     *
     * @method resetState
     * @private
     */
    resetState() {
      this.set('state', 'default');
    }
    resetObserver() {
      if (this.get('reset')) {
        Ember.run.scheduleOnce('actions', this, 'resetState');
      }
    }
    get text() {
      return this.getWithDefault(`${this.get('state')}Text`, this.get('defaultText'));
    }

    /**
     * @method click
     * @private
     */
    click() {
      let action = this.get('onClick');
      let preventConcurrency = this.get('preventConcurrency');
      if (action === null || action === undefined) {
        return;
      }
      if (!preventConcurrency || !this.get('isPending')) {
        let promise = action(this.get('value'));
        if (promise && typeof promise.then === 'function' && !this.get('isDestroyed')) {
          this.set('state', 'pending');
          promise.then(() => {
            if (!this.get('isDestroyed')) {
              this.set('state', 'fulfilled');
            }
          }, () => {
            if (!this.get('isDestroyed')) {
              this.set('state', 'rejected');
            }
          });
        }
      }
      return this.get('bubble');
    }
    init() {
      super.init(...arguments);

      // deprecate arguments used for attribute bindings only
      Ember.runInDebug(() => {
        [['disabled', true], ['title', 'foo']].forEach(_ref => {
          let [mapping, value] = _ref;
          let argument = mapping.split(':')[0];
          let attribute = mapping.includes(':') ? mapping.split(':')[1] : argument;
          let deprecationMessage = `Argument ${argument} of <BsButton> component is deprecated. Its only purpose ` + `was setting the HTML attribute ${attribute} of the control element. You should use ` + `angle bracket component invocation syntax instead:\n` + `Before:\n` + `  {{bs-button ${attribute}=${typeof value === 'string' ? `"${value}"` : value}}}\n` + `  <BsButton @${attribute}=${typeof value === 'string' ? `"${value}"` : `{{${value}}}`} />\n` + `After:\n` + `  <BsButton ${typeof value === 'boolean' ? attribute : `${attribute}="${value}"`} />\n` + `A codemod is available to help with the required migration!`;
          (true && !(
          // eslint-disable-next-line ember/no-attrs-in-components
          !Object.keys(this.attrs).includes(argument)) && Ember.deprecate(deprecationMessage, !Object.keys(this.attrs).includes(argument), {
            id: `ember-bootstrap.deprecated-argument.button#${argument}`,
            until: '4.0.0',
            url: 'https://github.com/kaliber5/ember-bootstrap-codemods/blob/master/transforms/deprecated-attribute-arguments/README.md'
          }));
        });
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "defaultText", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "pendingText", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "fulfilledText", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "rejectedText", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "_disabled", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "__disabled", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "__disabled"), _class2.prototype), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "buttonType", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "active", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "block", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "bubble", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "iconActive", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "iconInactive", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "icon", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "value", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "preventConcurrency", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "state", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'default';
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class2.prototype, "isPending", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class2.prototype, "isFulfilled", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class2.prototype, "isRejected", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class2.prototype, "isSettled", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class2.prototype, "reset", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class2.prototype, "size", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class2.prototype, "sizeClass", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class2.prototype, "typeClass", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class2.prototype, "title", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor26 = _applyDecoratedDescriptor(_class2.prototype, "onClick", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "resetObserver", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "resetObserver"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "text", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "text"), _class2.prototype)), _class2)) || _class) || _class) || _class) || _class) || _class);
  _exports.default = Button;
});