define("ember-svg-jar/inlined/dashboard-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 1h-6v5h6V1zm-6-1a1 1 0 00-1 1v5a1 1 0 001 1h6a1 1 0 001-1V1a1 1 0 00-1-1h-6zM7 13H1v4h6v-4zm-6-1a1 1 0 00-1 1v4a1 1 0 001 1h6a1 1 0 001-1v-4a1 1 0 00-1-1H1zM17 10h-6v7h6v-7zm-6-1a1 1 0 00-1 1v7a1 1 0 001 1h6a1 1 0 001-1v-7a1 1 0 00-1-1h-6zM7 1H1v8h6V1zM1 0a1 1 0 00-1 1v8a1 1 0 001 1h6a1 1 0 001-1V1a1 1 0 00-1-1H1z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});