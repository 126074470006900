define("ember-bootstrap/components/base/bs-form/element/control/checkbox", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/control", "ember-bootstrap/components/base/bs-form/element/control/input"], function (_exports, _component, _control, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  /**
  
   @class FormElementControlCheckbox
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  let FormElementControlCheckbox = (_dec = (0, _component.tagName)('input'), _dec2 = (0, _component.attributeBindings)(..._input.baseAttributes, 'value:checked', 'type'), _dec(_class = _dec2(_class = class FormElementControlCheckbox extends _control.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "type", 'checkbox');
    }
    click(event) {
      this.get('onChange')(event.target.checked);
    }
  }) || _class) || _class);
  _exports.default = FormElementControlCheckbox;
});