define("ember-bootstrap-modals-manager/components/ebmm-modals-container/base", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "c1hl77+J",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-bootstrap-modals-manager/components/ebmm-modals-container/base.hbs"
  });
  let BaseModal = (_dec = Ember._action, _dec2 = Ember._action, (_class = class BaseModal extends _component.default {
    confirm(v) {
      this.args.onConfirm(v);
    }
    decline(v) {
      this.args.onDecline(v);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "confirm", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "confirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "decline", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "decline"), _class.prototype)), _class));
  _exports.default = BaseModal;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BaseModal);
});