define("ember-leaflet/components/marker-layer", ["exports", "ember-leaflet/components/interactive-layer", "ember-leaflet/mixins/draggability", "ember-leaflet/mixins/div-overlayable", "ember-leaflet/macros/to-lat-lng"], function (_exports, _interactiveLayer, _draggability, _divOverlayable, _toLatLng) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _interactiveLayer.default.extend(_draggability.default, _divOverlayable.default, {
    leafletRequiredOptions: Object.freeze(['location']),
    leafletOptions: Object.freeze(['icon', 'clickable', 'draggable', 'keyboard', 'title', 'alt', 'zIndexOffset', 'opacity', 'riseOnHover', 'riseOffset']),
    leafletEvents: Object.freeze(['dragstart', 'drag', 'dragend', 'move', 'moveend', 'remove', 'add', 'popupopen', 'popupclose']),
    leafletProperties: Object.freeze(['zIndexOffset', 'opacity', 'location:setLatLng']),
    location: (0, _toLatLng.default)(),
    createLayer() {
      return this.L.marker(...this.get('requiredOptions'), this.get('options'));
    },
    // icon observer separated from generated (leaflet properties) due to a
    // leaflet bug where draggability is lost on icon change
    // eslint-disable-next-line ember/no-observers
    iconDidChange: Ember.observer('icon', function () {
      this._layer.setIcon(this.get('icon'));
      if (this.get('draggable')) {
        this._layer.dragging?.enable();
      } else {
        this._layer.dragging?.disable();
      }
    })
  });
  _exports.default = _default;
});