define('ember-collapsible-panel/components/cp-panel/component', ['exports', 'ember-collapsible-panel/components/cp-panel/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    panelActions: Ember.inject.service(),
    dependencyChecker: Ember.inject.service(),
    shouldAnimate: Ember.computed.and('dependencyChecker.hasLiquidFire', 'animate'),

    disabled: false,

    group: null, // passed in if rendered as part of a {{cp-panels}} group

    classNames: ['cp-Panel'],
    classNameBindings: ['isOpen:cp-is-open:cp-is-closed', 'disabled:cp-is-disabled'],

    // Caller can overwrite
    name: Ember.computed.oneWay('elementId'),

    panelState: Ember.computed('name', function () {
      const name = this.get('name');
      return this.get(`panelActions.state.${name}`);
    }),

    isOpen: Ember.computed.readOnly('panelState.isOpen'),
    isClosed: Ember.computed.not('isOpen'),

    panelsWrapper: null,
    animate: true,

    didReceiveAttrs() {
      this._super(...arguments);

      // If caller passes in open=, use it
      if (this.get('open') !== undefined) {
        this.set('panelState.boundOpenState', this.get('open'));
      }
    },

    // Register with parent panels component
    didInsertElement() {
      this._super(...arguments);
      Ember.run.scheduleOnce('afterRender', () => {
        let group = this.get('group');

        if (group) {
          this.get('panelState').set('group', group);
        }
      });
    },

    // Custom action called when toggling that can be provided by caller
    didToggle() {},

    actions: {
      toggleIsOpen() {
        if (this.get("disabled")) {
          return;
        }
        let name = this.get('name');

        this.get('panelActions').toggle(name);

        this.didToggle(name);
      }
    }
  });
});