define("ember-modals-manager-internal/services/emmi-modals-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class ModalsManager extends Ember.Service {
    constructor() {
      super(...arguments);
      _defineProperty(this, "modalIsOpened", false);
      _defineProperty(this, "modalsContainerPath", 'emmi-modals-container');
      _defineProperty(this, "modalDefer", null);
      _defineProperty(this, "defaultOptions", {
        title: 'Default Title',
        body: 'Default Body',
        footer: '',
        confirm: 'Yes',
        decline: 'No',
        cancel: 'Cancel'
      });
      _defineProperty(this, "options", {});
      _defineProperty(this, "componentName", null);
    }
    show(componentName, options) {
      (true && !(!this.modalIsOpened) && Ember.assert('Only one modal may be opened in the same time!', !this.modalIsOpened));
      const opts = Object.assign({}, this.defaultOptions, options);
      Ember.set(this, 'modalIsOpened', true);
      Ember.set(this, 'options', opts);
      Ember.set(this, 'componentName', componentName);
      const modalDefer = Ember.RSVP.defer();
      Ember.set(this, 'modalDefer', modalDefer);
      return modalDefer.promise;
    }

    /**
     * Shows `alert`-modal
     */
    alert(options) {
      return this.show(`${this.modalsContainerPath}/alert`, options);
    }

    /**
     * Shows `confirm`-modal
     */
    confirm(options) {
      return this.show(`${this.modalsContainerPath}/confirm`, options);
    }

    /**
     * Shows `prompt`-modal
     */
    prompt(options) {
      return this.show(`${this.modalsContainerPath}/prompt`, options);
    }

    /**
     * Shows `prompt-confirm`-modal
     */
    promptConfirm(options) {
      (true && !(!!options.promptValue) && Ember.assert('"options.promptValue" must be defined and not empty', !!options.promptValue));
      return this.show(`${this.modalsContainerPath}/prompt-confirm`, options);
    }

    /**
     * Show `check-confirm`-modal
     */
    checkConfirm(options) {
      return this.show(`${this.modalsContainerPath}/check-confirm`, options);
    }

    /**
     * Shows `progress`-modal. This modal doesn't have any controls and is auto-closed when progress is completed
     */
    progress(options) {
      (true && !(options && Ember.isArray(options.promises)) && Ember.assert('`options.promises` must be an array', options && Ember.isArray(options.promises)));
      return this.show(`${this.modalsContainerPath}/progress`, options);
    }
    process(options) {
      (true && !(options && options.process) && Ember.assert('`options.process` must be defined', options && options.process));
      return this.show(`${this.modalsContainerPath}/process`, options);
    }
    onConfirmClick(v) {
      var _this$modalDefer;
      Ember.set(this, 'modalIsOpened', false);
      (_this$modalDefer = this.modalDefer) === null || _this$modalDefer === void 0 ? void 0 : _this$modalDefer.resolve(v);
      this.clearOptions();
    }
    onDeclineClick(v) {
      var _this$modalDefer2;
      Ember.set(this, 'modalIsOpened', false);
      (_this$modalDefer2 = this.modalDefer) === null || _this$modalDefer2 === void 0 ? void 0 : _this$modalDefer2.reject(v);
      this.clearOptions();
    }
    clearOptions() {
      Ember.set(this, 'options', {});
    }
  }
  _exports.default = ModalsManager;
});