define("ember-loading/decorator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loading;
  function loading(_target, _propertyKey, desc) {
    let orig = desc.value;
    (true && !(typeof orig === 'function') && Ember.assert('The @loading decorator must be applied to methods', typeof orig === 'function'));
    desc.value = function () {
      let owner = Ember.getOwner(this);
      (true && !(!!owner) && Ember.assert('The target of the @loading decorator must have an owner.', !!owner));
      let service = owner.lookup('service:loading');
      return service.run(this, orig, ...arguments);
    };
  }
  ;
});