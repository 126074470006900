define("ember-svg-jar/inlined/dashboard-shipowner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.394 1.803a6.5 6.5 0 017.211 0l3.232 2.154a4.5 4.5 0 011.825 4.999L16.634 12.5H1.674L.51 9.069A4.5 4.5 0 012.277 3.88l3.117-2.078zm6.657.832a5.5 5.5 0 00-6.102 0L2.832 4.713A3.5 3.5 0 001.46 8.748L2.39 11.5h13.492l.82-2.823a3.5 3.5 0 00-1.42-3.888L12.05 2.635z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.66 11.738a.5.5 0 01.425-.238H16a.5.5 0 01.432.248l1.5 2.572a.5.5 0 01-.056.58l-1.5 1.715a.501.501 0 01-.169.126L16 16.286l.207.455h-.001l-.003.002-.008.003-.028.012-.101.043a9.25 9.25 0 01-.382.145c-.332.119-.816.276-1.437.432C13.005 17.69 11.213 18 9 18s-3.984-.31-5.206-.623a14.613 14.613 0 01-1.41-.432 9.003 9.003 0 01-.473-.189l-.027-.012-.008-.004-.002-.001h-.001l.212-.453-.213.452a.5.5 0 01-.154-.113L.133 14.911a.5.5 0 01-.059-.602l1.585-2.571zm.721 4.132l.017.007c.072.03.183.075.33.13.295.108.737.254 1.313.401C5.193 16.703 6.881 17 9 17c2.12 0 3.829-.297 5.003-.592.587-.148 1.04-.295 1.344-.403.152-.055.266-.1.341-.13l.01-.005 1.189-1.357-1.174-2.013H2.363l-1.238 2.01 1.256 1.36zM6.5 5.5A.5.5 0 017 5h4a.5.5 0 010 1H7a.5.5 0 01-.5-.5zM5.7 7.1a.5.5 0 01.7.1c.46.612 1.266 1.313 2.181 1.511.445.097.92.077 1.414-.133.498-.212 1.045-.63 1.605-1.378a.5.5 0 01.8.6c-.64.853-1.317 1.401-2.014 1.698-.7.298-1.387.327-2.017.19C7.134 9.422 6.141 8.522 5.6 7.8a.5.5 0 01.1-.7z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 3.5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V4a.5.5 0 01.5-.5zM9 15.5c-3.41 0-4.538 0-8.07-.505l.14-.99c3.465.495 4.543.495 7.923.495H9c3.394 0 4.961 0 7.918-.493l.164.986c-3.04.507-4.678.507-8.073.507H9z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});