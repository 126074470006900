define('@fortawesome/pro-light-svg-icons', ['exports'], function (exports) { 'use strict';

  var faPencil = {
    prefix: 'fal',
    iconName: 'pencil',
    icon: [512, 512, [9999, 61504, "pencil-alt"], "f303", "M373.2 16.97C395.1-4.901 430.5-4.901 452.4 16.97L495 59.6C516.9 81.47 516.9 116.9 495 138.8L182.3 451.6C170.9 462.9 156.9 471.2 141.5 475.8L20.52 511.3C14.9 512.1 8.827 511.5 4.687 507.3C.5466 503.2-1.002 497.1 .6506 491.5L36.23 370.5C40.76 355.1 49.09 341.1 60.44 329.7L373.2 16.97zM429.8 39.6C420.4 30.22 405.2 30.22 395.8 39.6L341 94.4L417.6 170.1L472.4 116.2C481.8 106.8 481.8 91.6 472.4 82.23L429.8 39.6zM109.6 402.4L173.4 415.2L394.1 193.6L318.4 117L96.84 338.6L109.6 402.4zM70.51 370.2C69.08 373.2 67.88 376.3 66.93 379.5L39.63 472.4L132.4 445.1C135.7 444.1 138.8 442.9 141.8 441.5L92.86 431.7C86.53 430.4 81.58 425.5 80.31 419.1L70.51 370.2z"]
  };
  var faCaretRight = {
    prefix: 'fal',
    iconName: 'caret-right',
    icon: [256, 512, [], "f0da", "M64 128v255.1c0 28.37 34.5 42.74 54.63 22.62l128-127.1c12.5-12.5 12.5-32.75 0-45.25l-128-127.1C98.5 85.27 64 99.52 64 128zM224 256l-128 127.1V128L224 256z"]
  };
  var faBars = {
    prefix: 'fal',
    iconName: 'bars',
    icon: [448, 512, ["navicon"], "f0c9", "M0 80C0 71.16 7.164 64 16 64H432C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H16C7.164 96 0 88.84 0 80zM0 240C0 231.2 7.164 224 16 224H432C440.8 224 448 231.2 448 240C448 248.8 440.8 256 432 256H16C7.164 256 0 248.8 0 240zM432 416H16C7.164 416 0 408.8 0 400C0 391.2 7.164 384 16 384H432C440.8 384 448 391.2 448 400C448 408.8 440.8 416 432 416z"]
  };
  var faLocationArrow = {
    prefix: 'fal',
    iconName: 'location-arrow',
    icon: [448, 512, [], "f124", "M47.1 256C32.71 256 19.55 245.2 16.6 230.2C13.65 215.2 21.73 200.2 35.88 194.4L387.9 50.38C399.8 45.5 413.5 48.26 422.6 57.37C431.7 66.49 434.5 80.19 429.6 92.12L285.6 444.1C279.8 458.3 264.8 466.3 249.8 463.4C234.8 460.4 223.1 447.3 223.1 432V256H47.1zM256 224V432L400 80L47.1 224H256z"]
  };
  var faRepeat = {
    prefix: 'fal',
    iconName: 'repeat',
    icon: [512, 512, [128257], "f363", "M496 240C487.2 240 480 247.2 480 256c0 79.41-64.59 144-144 144H192L192 336c0-6.219-3.594-11.84-9.219-14.5C180.6 320.5 178.3 320 176 320c-3.672 0-7.312 1.25-10.25 3.719l-96 80C66.11 406.8 64 411.3 64 416s2.109 9.254 5.75 12.29l96 80c4.781 4 11.45 4.812 17.03 2.219C188.4 507.9 192 502.2 192 496L192 432H336c97.05 0 176-78.97 176-176C512 247.2 504.8 240 496 240zM160 461.8L105 416L160 370.2V461.8zM176 112l143.1-.002L320 176c0 6.219 3.594 11.84 9.219 14.5C331.4 191.5 333.7 192 336 192c3.672 0 7.312-1.25 10.25-3.719l96-80C445.9 105.2 448 100.7 448 95.1s-2.109-9.253-5.75-12.28l-96-80c-4.781-4-11.45-4.812-17.03-2.219C323.6 4.148 320 9.773 320 15.99L319.1 80H176C78.95 80 0 158.1 0 256c0 8.844 7.156 16 16 16S32 264.8 32 256C32 176.6 96.59 112 176 112zM352 50.15l55 45.85L352 141.8V50.15z"]
  };
  var faEye = {
    prefix: 'fal',
    iconName: 'eye',
    icon: [576, 512, [128065], "f06e", "M416 256C416 326.7 358.7 384 288 384C217.3 384 160 326.7 160 256C160 185.3 217.3 128 288 128C358.7 128 416 185.3 416 256zM288 160C234.1 160 192 202.1 192 256C192 309 234.1 352 288 352C341 352 384 309 384 256C384 202.1 341 160 288 160zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM32 256C45.33 288 74.13 336 117.2 376C160.3 416 217.6 448 288 448C358.4 448 415.7 416 458.8 376C501.9 336 530.7 288 544 256C530.7 223.1 501.9 175.1 458.8 136C415.7 95.99 358.4 64 288 64C217.6 64 160.3 95.99 117.2 136C74.13 175.1 45.33 223.1 32 256V256z"]
  };
  var faArrowRotateRight = {
    prefix: 'fal',
    iconName: 'arrow-rotate-right',
    icon: [512, 512, [8635, "arrow-right-rotate", "arrow-rotate-forward", "redo"], "f01e", "M255.9 32.11c79.47 0 151.8 41.76 192.1 109.4V48C448 39.16 455.2 32 464 32S480 39.16 480 48v128C480 184.8 472.8 192 464 192h-128C327.2 192 320 184.8 320 176S327.2 160 336 160h85.85C387.5 100.7 324.9 64 256 64C150.1 64 64 150.1 64 256s86.13 192 192 192c59.48 0 114.7-26.91 151.3-73.84c5.438-7 15.48-8.281 22.47-2.75c6.953 5.438 8.187 15.5 2.75 22.44c-42.8 54.75-107.3 86.05-176.7 86.05C132.4 479.9 32 379.5 32 256S132.4 32.11 255.9 32.11z"]
  };
  var faRedo = faArrowRotateRight;
  var faTrash = {
    prefix: 'fal',
    iconName: 'trash',
    icon: [448, 512, [], "f1f8", "M432 64C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H413.7L388.2 452.6C385.9 486.1 357.1 512 324.4 512H123.6C90.01 512 62.15 486.1 59.75 452.6L34.29 96H16C7.164 96 0 88.84 0 80C0 71.16 7.164 64 16 64H111.1L137 22.56C145.8 8.526 161.2 0 177.7 0H270.3C286.8 0 302.2 8.526 310.1 22.56L336.9 64H432zM177.7 32C172.2 32 167.1 34.84 164.2 39.52L148.9 64H299.1L283.8 39.52C280.9 34.84 275.8 32 270.3 32H177.7zM381.6 96H66.37L91.67 450.3C92.87 467 106.8 480 123.6 480H324.4C341.2 480 355.1 467 356.3 450.3L381.6 96z"]
  };
  var faEnvelope = {
    prefix: 'fal',
    iconName: 'envelope',
    icon: [512, 512, [128386, 9993, 61443], "f0e0", "M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM32 128V167.9L227.6 311.3C244.5 323.7 267.5 323.7 284.4 311.3L480 167.9V128C480 110.3 465.7 96 448 96H63.1C46.33 96 31.1 110.3 31.1 128H32zM32 207.6V384C32 401.7 46.33 416 64 416H448C465.7 416 480 401.7 480 384V207.6L303.3 337.1C275.1 357.8 236.9 357.8 208.7 337.1L32 207.6z"]
  };
  var faMinus = {
    prefix: 'fal',
    iconName: 'minus',
    icon: [448, 512, [8211, 8722, 10134, "subtract"], "f068", "M432 256C432 264.8 424.8 272 416 272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h384C424.8 240 432 247.2 432 256z"]
  };
  var faEllipsisVertical = {
    prefix: 'fal',
    iconName: 'ellipsis-vertical',
    icon: [128, 512, ["ellipsis-v"], "f142", "M64 384C81.67 384 96 398.3 96 416C96 433.7 81.67 448 64 448C46.33 448 32 433.7 32 416C32 398.3 46.33 384 64 384zM64 224C81.67 224 96 238.3 96 256C96 273.7 81.67 288 64 288C46.33 288 32 273.7 32 256C32 238.3 46.33 224 64 224zM64 128C46.33 128 32 113.7 32 96C32 78.33 46.33 64 64 64C81.67 64 96 78.33 96 96C96 113.7 81.67 128 64 128z"]
  };
  var faEllipsisV = faEllipsisVertical;
  var faEllipsis = {
    prefix: 'fal',
    iconName: 'ellipsis',
    icon: [448, 512, ["ellipsis-h"], "f141", "M352 256C352 238.3 366.3 224 384 224C401.7 224 416 238.3 416 256C416 273.7 401.7 288 384 288C366.3 288 352 273.7 352 256zM192 256C192 238.3 206.3 224 224 224C241.7 224 256 238.3 256 256C256 273.7 241.7 288 224 288C206.3 288 192 273.7 192 256zM96 256C96 273.7 81.67 288 64 288C46.33 288 32 273.7 32 256C32 238.3 46.33 224 64 224C81.67 224 96 238.3 96 256z"]
  };
  var faEllipsisH = faEllipsis;
  var faLocationDot = {
    prefix: 'fal',
    iconName: 'location-dot',
    icon: [384, 512, ["map-marker-alt"], "f3c5", "M272 192C272 236.2 236.2 272 192 272C147.8 272 112 236.2 112 192C112 147.8 147.8 112 192 112C236.2 112 272 147.8 272 192zM192 240C218.5 240 240 218.5 240 192C240 165.5 218.5 144 192 144C165.5 144 144 165.5 144 192C144 218.5 165.5 240 192 240zM384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384zM192 32C103.6 32 32 103.6 32 192C32 207.6 37.43 229 48.56 255.4C59.47 281.3 74.8 309.4 92.14 337.5C126.2 392.8 166.6 445.7 192 477.6C217.4 445.7 257.8 392.8 291.9 337.5C309.2 309.4 324.5 281.3 335.4 255.4C346.6 229 352 207.6 352 192C352 103.6 280.4 32 192 32z"]
  };
  var faMapMarkerAlt = faLocationDot;
  var faPlus = {
    prefix: 'fal',
    iconName: 'plus',
    icon: [448, 512, [10133, 61543, "add"], "2b", "M432 256C432 264.8 424.8 272 416 272h-176V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z"]
  };
  var faXmark = {
    prefix: 'fal',
    iconName: 'xmark',
    icon: [320, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z"]
  };
  var faTimes = faXmark;
  var faWandMagic = {
    prefix: 'fal',
    iconName: 'wand-magic',
    icon: [512, 512, ["magic"], "f0d0", "M12.28 403.7L403.7 12.28C419.3-3.337 444.7-3.337 460.3 12.28L499.7 51.72C515.3 67.34 515.3 92.66 499.7 108.3L108.3 499.7C92.66 515.3 67.34 515.3 51.72 499.7L12.28 460.3C-3.337 444.7-3.337 419.3 12.28 403.7V403.7zM356.7 206.1L477.1 85.66C480.2 82.53 480.2 77.47 477.1 74.34L437.7 34.91C434.5 31.79 429.5 31.79 426.3 34.91L305.9 155.3L356.7 206.1zM283.3 177.9L34.91 426.3C31.79 429.5 31.79 434.5 34.91 437.7L74.34 477.1C77.47 480.2 82.53 480.2 85.66 477.1L334.1 228.7L283.3 177.9z"]
  };
  var faMagic = faWandMagic;
  var faCheck = {
    prefix: 'fal',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M443.3 100.7C449.6 106.9 449.6 117.1 443.3 123.3L171.3 395.3C165.1 401.6 154.9 401.6 148.7 395.3L4.686 251.3C-1.562 245.1-1.562 234.9 4.686 228.7C10.93 222.4 21.06 222.4 27.31 228.7L160 361.4L420.7 100.7C426.9 94.44 437.1 94.44 443.3 100.7H443.3z"]
  };
  var faTriangleExclamation = {
    prefix: 'fal',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M256 360c-13.25 0-23.1 10.74-23.1 24c0 13.25 10.75 24 23.1 24c13.25 0 23.1-10.75 23.1-24C280 370.7 269.3 360 256 360zM256 320c8.843 0 15.1-7.156 15.1-16V160c0-8.844-7.155-16-15.1-16S240 151.2 240 160v144C240 312.8 247.2 320 256 320zM504.3 397.3L304.5 59.38C294.4 42.27 276.2 32.03 256 32C235.8 32 217.7 42.22 207.5 59.36l-199.9 338c-10.05 16.97-10.2 37.34-.4218 54.5C17.29 469.5 35.55 480 56.1 480h399.9c20.51 0 38.75-10.53 48.81-28.17C514.6 434.7 514.4 414.3 504.3 397.3zM476.1 435.1C472.7 443.5 464.8 448 455.1 448H56.1c-8.906 0-16.78-4.484-21.08-12c-4.078-7.156-4.015-15.3 .1562-22.36L235.1 75.66C239.4 68.36 247.2 64 256 64c0 0-.0156 0 0 0c8.765 .0156 16.56 4.359 20.86 11.64l199.9 338C480.1 420.7 481.1 428.8 476.1 435.1z"]
  };
  var faExclamationTriangle = faTriangleExclamation;

  exports.faBars = faBars;
  exports.faCaretRight = faCaretRight;
  exports.faCheck = faCheck;
  exports.faEllipsisH = faEllipsisH;
  exports.faEllipsisV = faEllipsisV;
  exports.faEnvelope = faEnvelope;
  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faEye = faEye;
  exports.faLocationArrow = faLocationArrow;
  exports.faMagic = faMagic;
  exports.faMapMarkerAlt = faMapMarkerAlt;
  exports.faMinus = faMinus;
  exports.faPencil = faPencil;
  exports.faPlus = faPlus;
  exports.faRedo = faRedo;
  exports.faRepeat = faRepeat;
  exports.faTimes = faTimes;
  exports.faTrash = faTrash;

  Object.defineProperty(exports, '__esModule', { value: true });

});
