define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.577 2.893a1.5 1.5 0 012.03-.294l.79.55a1.5 1.5 0 01.378 2.079L11.75 6.723 7.16 12.657a1.5 1.5 0 01-.47.4l-1.776.966a1 1 0 01-1.467-1.022l.287-1.963a1.5 1.5 0 01.274-.67l4.426-6.041 1.144-1.434zm1.458.526a.5.5 0 00-.676.099l-.803 1.004a84.022 84.022 0 011.67 1.198l.725-1.058a.5.5 0 00-.127-.693l-.789-.55zm-.398 3.109l-4.27 5.517a.5.5 0 01-.156.133l-1.776.967.287-1.963a.5.5 0 01.091-.223l4.134-5.643a81.26 81.26 0 011.69 1.212z\" fill=\"#1E1E1E\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});