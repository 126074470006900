define("ember-tag-input/templates/components/tag-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ncQA9D0N",
    "block": "{\"symbols\":[\"tag\",\"index\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"li\"],[14,0,\"emberTagInput-tag\"],[12],[2,\"\\n    \"],[18,3,[[32,1]]],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[24,0,\"emberTagInput-remove\"],[4,[38,0],[[32,0],\"removeTag\",[32,2]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13]],\"parameters\":[1,2]}]]],[10,\"li\"],[14,0,\"emberTagInput-new\"],[12],[2,\"\\n  \"],[10,\"input\"],[15,\"disabled\",[32,0,[\"readOnly\"]]],[15,0,[30,[36,7],[\"emberTagInput-input js-ember-tag-input-new\",[30,[36,2],[[35,6],\" is-disabled\"],null]],null]],[15,\"maxlength\",[32,0,[\"maxlength\"]]],[15,\"placeholder\",[32,0,[\"placeholder\"]]],[15,\"aria-label\",[32,0,[\"ariaLabel\"]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"_isRemoveButtonVisible\",\"if\",\"tags\",\"-track-array\",\"each\",\"readOnly\",\"concat\"]}",
    "moduleName": "ember-tag-input/templates/components/tag-input.hbs"
  });
  _exports.default = _default;
});