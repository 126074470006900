define("@bagaar/ember-breadcrumbs/templates/components/breadcrumbs-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2bmJmdwx",
    "block": "{\"symbols\":[\"container\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"breadcrumbsService\",\"containers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"element\"]]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"li\"],[16,0,[32,1,[\"itemClass\"]]],[17,2],[12],[2,\"\\n      \"],[18,3,[[32,1,[\"linkClass\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-in-el-null\",\"in-element\",\"-track-array\",\"each\"]}",
    "moduleName": "@bagaar/ember-breadcrumbs/templates/components/breadcrumbs-item.hbs"
  });
  _exports.default = _default;
});