define("ember-svg-jar/inlined/dashboard-terminals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.464 6.352h1.62v6.377c0 .375.458.563.916.563s.916-.188.916-.563V6.352h1.62c.353 0 .553-.388.553-.834 0-.387-.165-.81-.552-.81H6.464c-.388 0-.552.423-.552.81 0 .446.2.834.552.834z\" fill=\"#505152\"/><path d=\"M17.297 0H.703A.703.703 0 000 .703v16.594c0 .388.315.703.703.703h16.594a.703.703 0 00.703-.703V.703A.703.703 0 0017.297 0zm-.703 16.594H1.406V1.406h15.188v15.188z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});