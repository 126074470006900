define("ember-set-helper/helpers/-set-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Placeholder = void 0;
  class Placeholder {
    constructor(path) {
      this.path = path;
      if (true /* DEBUG */) {
        // just in case anybody tries to do anything crazy
        Object.seal(this);
      }
    }
    unknownProperty(key) {
      this.path = this.path === null ? key : `${this.path}.${key}`;
      return this;
    }
  }
  _exports.Placeholder = Placeholder;
  function setPlaceholder() {
    return new Placeholder(null);
  }
  var _default = Ember.Helper.helper(setPlaceholder);
  _exports.default = _default;
});