define("ember-svg-jar/inlined/up-16px", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.694 10.72a1 1 0 01-1.414-.026l-3.162-3.28-3.423 3.305a1 1 0 01-1.39-1.438l4.863-4.695 4.552 4.72a1 1 0 01-.026 1.414z\" fill=\"#1E1E1E\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});